@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.enews-form {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    align-items: center;
    @include component-spacing;

    form {
        padding: rem(30) 0 0 0;
    }

    &__inner {
        width: 100%;
        padding: rem(60) rem($padding);
        background-color: $white-two;
    }

    form,
    &__wrapper,
    &__row {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;
    }

    &__row {
        margin: 0 0 rem(12) 0;

        &--submit {
            margin: 0;
        }
    }

    &__intro,
    &__disclaimer {
        width: 100%;
        text-align: center;
    }

    &__disclaimer {

        margin: rem(12) auto rem(40) auto;

        p {
            font-size: rem(12);
            line-height: rem(20);
        }
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    
    .enews-form {

        &__inner {
            background-color: $white;
            padding-top: 0;
            padding-bottom: 0;
            max-width: map-get($container-max-widths, 'md');
        }

        &__wrapper {
            background-color: $white-two;
            padding: rem(80) rem(95);
        }

        &__intro,
        &__disclaimer {
            max-width: rem(360);
        }

    }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .enews-form {

        &__inner {
            max-width: rem(745);
        }
        
    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    .enews-form {

    }
}