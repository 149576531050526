@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_comp-bg";
@import "./_rtl";

.accordion {

    width: 100%;
    margin: 0 0 rem(8) 0;

    &.active {

        .accordion__title {

            background-color: $white-two;
            box-shadow: none;

            svg {
                transform: rotate(-180deg);
            }
        }
        
    }

    &__title {
        @include clear-default-appearance;
        width: 100%;
        text-align: left;
        border-radius: 0;
        cursor: pointer;
        padding: rem(22) rem(60) rem(24) rem(24);
        position: relative;
        box-shadow: 0 rem(2) rem(4) 0 rgba($black, 0.2);
        transition: background-color 0.5s ease;
        border: rem(3) solid $white;

        &:focus {
            outline: 0;
        }

        svg {
            position: absolute;
            right: rem(24);
            top: rem(30);
            width: rem(20);
            height: rem(13);
            z-index: 1;
            transition: transform 0.5s ease;
        }
    }

    &__content {
        width: 100%;
        background-color: $white-two;
        padding: rem(32) rem(35);
    }

    &__children {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        align-content: stretch;
        justify-content: space-between;
        padding: rem(10) 0 0 0;

    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .accordion {

        margin: 0 0 rem(12) 0;

        &__title {
            
            &:hover,
            &:focus {
                background-color: $white;
                color: $charcoal;
                border-color: $charcoal;

                svg {
                    color: $charcoal;
                }
            }
        }

        &:hover {

            .accordion__title svg {
                transform: rotate(-180deg);
            }

        }

        &__children {
            padding: rem(30) 0 0 0;
        }

    }


}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .accordion {

        &__title {
            padding: rem(32) rem(84) rem(32) rem(32);

            svg {
                top: rem(39);
                right: rem(30);
            }
        }

        &__content {
            padding: rem(40) rem(60) rem(40) rem(60);
        }

        &__children {
            padding: rem(40) 0 0 0;
        }

    }

}