@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

$component-spacing: (
    "xs": 40,
    "md": 60,
    "lg": 70,
    "xl": 80,
);

// TODO: see if we can change this to margin so that we dont need the extra div for background
.component-container {
    width: 100%;
    
    @each $breakpoint, $spacing in $component-spacing {
        @media screen and (min-width: map-get($grid-breakpoints, #{$breakpoint})) {
            padding-top: rem($spacing);
            padding-bottom: rem($spacing);
    
            &--no-spacing {
                padding-top: 0;
                padding-bottom: 0;
            }
    
            &--spacing-top {
                padding-top: rem($spacing);
                padding-bottom: 0;
            }
    
            &--spacing-bottom {
                padding-top: 0;
                padding-bottom: rem($spacing);
            }
        }
    }
}

.component-background {
    padding: rem(30) 0;

    @each $bg, $item in $component-bg-styles {
        &.#{$bg} {
            background-color: $item;
        }
    }

    // @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    //     padding: rem(80) 0;
    // }

    // @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    //     padding: rem(160) 0;
    // }
}
