@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_lang";
@import "./_comp-bg";

.gallery-item {

    width: 100%;
    overflow: hidden;
    position: relative;

    &__inner {
        padding: 0 rem(4);
        transition: opacity 0.5s ease, transform 0.5s ease;
        transform: scale(0.95);
        opacity: 0.2;
    }

    &__img {
        position: relative;
        width: 100%;
        padding: 56.25% 0 0 0;
        max-height: rem(190);
        margin: 0 0 rem(24) 0;
        background-color: $very-light-pink;
        overflow: hidden;
        border-radius: rem(2);
    }

    &__content {

        width: 100%;
        text-align: center;
        transition: opacity 0.6s ease, visibility 0.7s ease, transform 0.5s ease;
        opacity: 0;
        visibility: hidden;
        transform: translateY(15%);

        h4 {
            font-family: $latin-heading-font;
            font-weight: $latin-heading-font-weight;
            margin: 0 0 rem(10) 0;
            padding: 0;
        }

        p {
            margin: 0;
            padding: 0;
        }

    }

}

.carousel-wrapper__page.active {
    .gallery-item__inner {
        opacity: 1;
        transform: scale(1);
    }

    .gallery-item__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}


@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    
    .gallery-item {

        &__inner {
            padding: 0 rem(5);
        }

        &__img {
            max-height: 100%;
            padding: 0;
            height: rem(276);
        }

    }

}

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    
    .gallery-item {

        &__inner {
            padding: 0 rem(2);
            transform: scale(0.95);
        }

        &__img {
            height: rem(376);
            margin: 0 0 rem(45) 0;
        }

        &__content {
    
            h4 {
                margin: 0 0 rem(17) 0;
            }
    
        }
    

    }

}