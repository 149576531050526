@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";
@import "./_theme";

.home-banner {

    width: 100%;
    height: 100vh;
    padding: 0 rem($padding);
    position: relative;
    z-index: 1;

    &__item {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.9s ease, visibility 1s ease;
        opacity: 0;
        visibility: hidden;
        display: flex;
        align-items: center;
        align-content: center;
        flex-flow: row wrap;
        justify-content: flex-start;
        padding: 0 rem($padding);

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
    
    h1 {
        margin: 0;
        padding: 0;
        color: $charcoal;
        display: block;
        width: 100%;
        max-width: rem(350);
    }

    &__scroll {
        @include clear-default-appearance;
        cursor: pointer;
        position: absolute;
        bottom: rem($padding * 2);
        left: rem($padding);
        width: rem(35);
        height: rem(35);

        &:focus {
            outline: 0;
        }

        &:before {
            content: "";
            width: rem(17);
            height: rem(17);
            border-radius: 0 0 0 rem(3);
            border-left: rem(3) solid $charcoal;
            border-bottom: rem(3) solid $charcoal;
            transform: rotate(-45deg);
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%) rotate(-45deg);
        }

    }

    &__pause-play {

        @include clear-default-appearance;
        border: rem(3) solid $charcoal;
        border-radius: rem(999);
        width: rem(32);
        height: rem(32);
        cursor: pointer;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-flow: row wrap;
        position: absolute;
        right: rem(77);
        bottom: rem(40);
        display: none;

        &:before,
        &:after {
            content: "";
            width: rem(3);
            height: rem(10);
            background-color: $charcoal;
            border-radius: rem(999);
            display: block;
        }

        &:before {
            margin: 0 rem(4) 0 0;
        }

        &.paused {

            &:before {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: rem(5) 0 rem(5) rem(10);
                background-color: transparent;
                border-color: transparent transparent transparent $charcoal;
                border-radius: 0;
                margin: 0 0 0 rem(3);
            }

            &:after {
                display: none;
            }

        }

    }


}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .home-banner {

        transition: opacity 0.5s ease;
        opacity: 0;
        visibility: hidden;

        &.loaded {
            opacity: 1;
            visibility: visible;
        }

        h1 {
            max-width: 80%;
        }

        &__item {
            padding: 0 rem(70);
        }

        &__scroll {
            left: rem(77);
            bottom: rem(50);
            transition: bottom 0.5s ease;

            &:hover {
                outline: 0;
                bottom: rem(40);
            }

            &:focus {
                border: rem(2) solid $charcoal;
            }
        }

        &__pause-play {

            display: flex;
            transition: background-color 0.4s ease;

            &.paused {
                &:hover,
                &:focus {
                    &:before {
                        background-color: transparent;
                        border-color: transparent transparent transparent $white;
                    }
                }
            }

            &:hover,
            &:focus {
                outline: 0;
                background-color: $charcoal;

                &:before,
                &:after {
                    background-color: $white;
                }
            }
            
        }

    }

}


/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .home-banner {

        &__item {
            padding: 0 12vw;

            &.active {

                h1 {
                    opacity: 1;
                    transform: translateY(0);
                }

            }
        }

        &__scroll {
            left: 12.5vw;
        }

        &__pause-play {
            right: rem(40);
        }

    }
    
}

/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .home-banner {

        h1 {
            max-width: 85%;
            transition: transform 0.7s ease, opacity 0.6s ease;
            opacity: 0;
            transform: translateY(40%);
        }
    }

}