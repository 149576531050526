@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        .gallery-item {
            &__content {
                h4,
                p {
                    color: $white;
                }
            }
        }
    }
}
