body.#{(map-get($languages, 'arabic'))}:not(.ie-11) {

    .update-card {

        &__icon {

            svg {
                transform: rotate(90deg);
            }
        }

    }

}