@each $theme, $item in $template-themes {

    body.#{$theme}{

        $itemColour: $item;

        .accordion {

            &.active {

                .accordion__title,
                .accordion__title svg {
                    color: $charcoal;
                }

            }

            &__title {
                @include font-smoothing;
                background-color: $itemColour;
                border-color: $itemColour;
                color: $white;

            }

            &__content {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: $itemColour;
                }

            }

        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {

            .accordion {

                &__title {
                    
                    &:hover,
                    &:focus {
                        background-color: $white;
                        color: $itemColour;

                        svg {
                            color: $itemColour;
                        }
                    }
                }
            }
        }

    }

}