@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";
@import "./_theme";
@import "./_lang";

.nav-item {

    margin: 0;
    padding: 0 0 rem(24) 0;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-flow: row wrap;

    &:last-child {
        padding: 0;
    }

    &.current-item > a {
        
        &:after {
            width: 100%;
        }

    }

    a {
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;
        color: $charcoal;
        text-decoration: none;
        position: relative;
        display: block;
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(28);
        padding: 0 0 rem(3) 0;

        &:after {
            content: "";
            width: 0.001%;
            transition: width 0.4s ease;
            height: rem(3);
            background-color: $charcoal;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            outline: 0;
        }
    }

    & > ul {

        padding: rem(12) 0 0 rem(8);

        .nav-item  {
            font-size: rem(16);
            line-height: rem(26);
            padding: 0 0 rem(8) 0;
            position: relative;

            &:last-child {
                padding: 0;

                a {
                    margin: 0;
                }
            }

            &.current-item a {
                &:after {
                    width: calc(100% - 15px);
                }
            }

            a {
                font-size: rem(16);
                line-height: rem(26);
                padding: 0 0 rem(3) rem(15);
                margin: 0 0 rem(5) 0;

                &:after {
                    left: rem(15);
                }

                &:before {
                    content: "";
                    width: rem(4);
                    height: rem(2);
                    background-color: $charcoal;
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 0;
                }

            }
        }

    }

    button {

        @include clear-default-appearance;
        width: rem(30);
        height: rem(30);
        cursor: pointer;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-flow: row wrap;
        margin: 0 0 0 rem(5);

        &:focus {
            outline: 0;
        }

        svg {
            width: rem(17);
            height: rem(10);
            color: $charcoal;
        }

        &.sub-active {

            svg {
                transform: rotate(180deg);
            }

            & ~ ul {
                display: block;
            }
        }

        & ~ ul {
            display: none;
        }

    }
}



@media screen and (min-width:  map-get($grid-breakpoints, 'md')) {


    .nav-item {

        a,
        button svg {
            transition: color 0.2s ease;
        }

        button {
            
            &:hover {
                border: none;
            }

            &:focus {
                border: rem(2) solid $charcoal;
            }
        }

        a {

            &:hover,
            &:focus {
                &:after {
                    width: 100%;
                }
            }

        }

        & > ul .nav-item {
            a {

                &:hover,
                &:focus {
                    &:after {
                        width: calc(100% - 15px);
                    }
                }
            }

        }


    }



}


/*
    XLarge Screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

    .nav-item {
        width: auto;
        padding: rem(24) rem(16) rem(24) 0;
        position: relative;

        &.current-item > a {
            text-decoration: none;

            &:after {
                width: 100%;
            }
        }

        &:hover {

            a:after {
                width: 100%;
            }

            button {

                svg {
                    transform: rotate(180deg);
                }

                & ~ ul {
                    display: block;
                }
            }

        }

        &:focus {
            text-decoration: none;
        }

        button {


            &:hover {

                border: none;

                & ~ ul {
                    display: block;
                }

            }

            svg {
                width: rem(12);
                height: rem(15);
            }

        }

        &:last-child,
        &:nth-last-child(2) {
            & > ul {
                left: inherit;
                right: rem(30);
            }
        }

        &:last-child {
            padding: rem(24) 0;
        }

        a {

            padding: 0 0 rem(3) 0;
            color: $charcoal;
            font-size: rem(19);
            line-height: rem(28);

            &.current-item,
            &:focus {
                &:after {
                    width: 100%;
                }
            }

            &.current-item,
            &:hover,
            &:focus {
                text-decoration: none;
            }

        }

        button svg {
            color: $charcoal;
        }

        & > ul {

            @include shadow;
            position: absolute;
            top: 100%;
            left: 0;
            padding: rem(20) rem(24);
            border-radius: rem(2);
            width: 100%;
            width: max-content;
            min-width: rem(330);
            max-width: rem(450);
            background-color: $white;

            .nav-item {
                font-size: rem(19);
                line-height: rem(28);
                padding: 0 0 rem(12) 0;

                &:last-child {
                    padding: 0;
                }

                &.current-item {
                    
                    a {
                        
                        padding: 0 0 0 rem(15);
                        color: $charcoal;

                        &:before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                }

                a {
                    transition: padding 0.5s ease;
                    padding: 0;
                    font-size: rem(19);
                    line-height: rem(28);
                    color: $brownish-grey;
                    margin: 0;
                    width: 100%;

                    &:after {
                        display: none;
                    }

                    &:before {
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity 0.5s ease, visibility 0.6s ease;
                        width: rem(6);
                        height: rem(3);
                    }

                    &:hover,
                    &:focus {
                        padding: 0 0 0 rem(15);
                        color: $charcoal;
                        text-decoration: none;

                        &:before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

            }
        }


    }

}