@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        .share {
            button {
                border-color: $white;
                background-color: $white;

                svg {
                    color: $item;
                }

                &:hover,
                &:focus {
                    background-color: $item;

                    svg {
                        color: $white;
                    }
                }
            }

            &__tooltip ul li a svg {
                color: $white;
            }

            &__tooltip ul li a:focus {
                border-color: $white;
            }
        }
    }
}
