@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";
@import "./_theme";

.content-banner {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    padding: rem(150) 0 rem(36) 0;

    h1 {
        color: $charcoal;
        font-weight: 700;
        margin: 0 0 rem(20) 0;
        padding: 0;
        width: 100%;
        line-height: normal;

        span {
            display: block;
            width: 100%;
            max-width: rem(280);
        }
    }

    h2 {
        font-family: $latin-body-font;
        font-weight: $latin-body-font-weight;
        color: $brownish-grey;
        margin: 0;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        padding: 0 0 0 rem(32);

        span {
            display: block;
            width: 100%;
        }
    }

    h3 {
        font-family: $latin-body-font;
        font-weight: $latin-body-font-weight;
        color: $brownish-grey;
        margin: 0;
        padding: 0;
        position: absolute;
        top: rem(-46);
        left: rem($padding);
        max-width: rem(250);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @include centre-align-container;

    &__inner {
        padding-left: rem($padding);
        padding-right: rem($padding);
        position: relative;
    }

    &--reduced {
        padding: rem(185) 0 rem(60) 0;

        h1 {
            span {
                max-width: 100%;
            }
        }
    }

    .mini-caption {
        width: 100%;
    }
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "md")) {

    .mini-caption {
        width: 50%;
        max-width: 700px;
    }

    .content-banner {
        padding: rem(150) 0 rem(0) 0;
        opacity: 0;

        &.loaded {
            opacity: 1;

            h1,
            h2 {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }

        h1 {
            margin: 0 0 rem(30) 0;
            transition: opacity 0.6s ease, transform 0.5s ease;
            opacity: 0;
            visibility: hidden;
            transform: translateY(50%);
            font-size: 32px;

            span {
                max-width: 60%;
            }
        }

        h2 {
            padding: 0 rem(62);
            transition: opacity 0.6s ease, transform 0.5s ease;
            transition-delay: 0.3s;
            opacity: 0;
            visibility: hidden;
            transform: translateY(50%);
        }

        h3 {
            color: $brownish-grey;
        }

        &--reduced {
            padding: rem(185) rem($padding) rem(60) rem($padding);

            h1 {
                span {
                    max-width: 80%;
                }
            }
        }
    }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .content-banner {
        padding: rem(170) 0 rem(10) 0;

        h1 {
            padding: 0;
            margin: 0 0 rem(40) 0;
            font-size: 44px;

            span {
                max-width: 70%;
            }
        }

        h2 {
            padding: 0 rem(50) 0 30%;

            span {
                max-width: 85%;
            }
        }

        h3 {
            max-width: rem(450);
        }

        &--reduced {
            padding: rem(205) rem($padding) rem(60) rem($padding);

            h1 {
                span {
                    max-width: 90%;
                }
            }
        }
    }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    .content-banner {
        padding: rem(285) 0 rem(86) 0;

        h1 {
            padding: 0;
            margin: 0 0 rem(40) 0;
            font-size: 56px;

            span {
                max-width: rem(1010);
            }
        }

        h2 {
            margin: 0;
            justify-content: flex-start;
            padding: 0 0 0 44%;

            span {
                max-width: rem(555);
            }
        }

        &--reduced {
            padding: rem(244) 0 rem(80) 0;

            h1 {
                margin: 0 0 rem(30) 0;

                span {
                    max-width: rem(936);
                }
            }

            h2 {
                padding: 0 0 0 52%;
            }
        }
    }
}
