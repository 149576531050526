@each $bg, $item in $bg-styles {
    body.#{$bg}{
        
        .img-content-card {


            &__wrapper {
                background-color: transparent;
                box-shadow: none;
            }

            &__content {

                padding: rem(32) 0;
                
                h6,
                h3,
                p {
                    color: $white;
                }
            }

            &__cta .btn {
                background-color: $white;
                color: $item;
            }

        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {

            .img-content-card {

                &__content {
                    padding: rem(32);
                }

                &--no-shadow {
                    .img-content-card__content {
                        padding: rem(28);
                    }
                }

            }

        }


        /*
            Large Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {


            .img-content-card {

                &__content {
                    padding: rem(40) rem(48);
                }

                &--no-shadow {
                    .img-content-card__content {
                        padding: rem(30);
                    }
                }

            }
        }

        /*
            XLarge Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

            .img-content-card {

                &__content {

                    padding: rem(80) rem(95);

                }

                &--no-shadow {
                    .img-content-card__content {
                        padding: rem(60);
                    }
                }


            }

        }


    }
}