@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.icon-block-row {

    @include component-spacing;
    width: 100%;

    &__wrapper {
        width: 100%;
        background-color: $white-two;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        padding: rem(60) 0;
    }

    &__inner {
        padding-left: rem($padding);
        padding-right: rem($padding);
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
    }
    
    &__item {
        margin: 0 0 rem(20) 0;
        width: 100%;
        max-width: rem(280);
    }

    @include centre-align-container;

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .icon-block-row {

        &__inner {
            justify-content: space-between;
        }
        
        &__item {
            width: 45%;
            margin: 0 0 rem(30) 0;
        }
        

    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .icon-block-row {

        &__wrapper {
            padding: rem(80) 0;
        }
        
        &__item {
            width: 20%;
            margin: 0;
        }
        

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .icon-block-row {

        &__wrapper {
            padding: rem(160) 0;
        }

    }

}