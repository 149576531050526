@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";

.column-carousel {

    @include component-spacing;
    width: 100%;
    overflow: hidden;

    h2 {
        margin: 0;
        padding: 0 0 rem(12) 0;
    }

    h4 {
        padding: 0 0 rem(8) 0;
        margin: 0;
    }

    p {
        margin: 0;
        padding: 0;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        background-color: $white-two;
        padding: rem(60) 0;
    }

    &__inner {
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
    }

    &__left {
        width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    &__right {
        width: 100%;
        margin: rem(40) 0 0 0;
        position: relative;
    }

    &__item {

        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-flow: row wrap;

        &.even {
            .column-carousel__item-img {
                order: 0;
                margin: 0 0 rem(20) 0;
            }

            .column-carousel__item-content {
                margin: 0;
            }
        }

        a {
            display: block;
            width: 100%;
            text-decoration: none;
            color: $charcoal;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            flex-flow: row wrap;
        }

        &-img {
            width: 100%;
            position: relative;
            min-height: rem(195);
            padding: 56.3333% 0 0 0;
            border-radius: rem(2);
            overflow: hidden;
            order: 1;

            .bg-img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
            }

        }

        &-content {
            margin: 0 0 rem(20) 0;
        }

    }

    .carousel-wrapper__container {
        max-width: rem(350);
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .column-carousel {

        &__inner {
            justify-content: space-between;
            max-width: map-get($container-max-widths, 'md');
            align-items: center;
            align-content: center;
        }

        &__wrapper {
            padding: rem(60) 0;
        }

        &__left {
            width: 50%;
            padding: 0 10% 0 0;
        }

        &__right {
            width: 50%;
            margin: 0;
            height: auto;

            &-inner {
                width: rem(5000);
                height: auto;
                overflow: hidden;
            }

        }


        &__item {
            
            padding: rem(70) 0 0 0;

            &.even {
                padding: 0 0 rem(70) 0;
            }    

        }

        .carousel-wrapper {
            overflow: hidden;
            justify-content: flex-start;
        }

        .carousel__controls {
            justify-content: flex-start;
        }

    }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {


    .column-carousel {

        &__inner {
            max-width: map-get($container-max-widths, 'lg');
        }

        .carousel-wrapper__container {
            max-width: rem(285);
        }

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {


    .column-carousel {

        h2 {
            padding: 0 0 rem(20) 0;
        }

        &__inner {
            max-width: map-get($container-max-widths, 'xl');
        }

        &__wrapper {
            padding: rem(100) 0;
        }


        &__left {
            width: 40%;
        }


    }

}

.column-carousel {
    padding-bottom: 0;
}