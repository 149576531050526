body.arabic-text {
    
    .header {
    
        &__action-btn {
            font-family: $arabic-heading-font;
            font-weight: $arabic-heading-font-weight;
        }
    }
}

body.chinese-text {
    
    .header {
    
        &__action-btn {
            font-family: $chinese-heading-font;
            font-weight: $chinese-heading-font-weight;
            font-style: normal;
        }

    }

    /*
        Medium Screens
    */

    @media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

        .header {
            &.top {
                .header__left {

                    a span svg {
                        height: rem(25);
                    }
                }
            }
        }

    }

    /*
        XLarge Screens
    */

    @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

        .header {
            &.top {
                .header__left {

                    a span svg {
                        height: rem(30);
                    }
                }
            }
        }

    }

}

body.russian-text {
    
    .header {
    
        &__action-btn {
            font-family: $russian-heading-font;
            font-weight: $russian-heading-font-weight;
            font-style: normal;
        }
        
    }
}