@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .header__lower .header__language .dropdown__current {
            color: $charcoal;
        }

        .nav-item {
            a {
                color: $white;
                &:after {
                    background-color: $white;
                }
            }

            & > ul {
                .nav-item {
                    a {
                        &:before,
                        &:after {
                            background-color: $white;
                        }
                    }
                }
            }

            button {
                svg {
                    color: $white;
                }
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .header:not(.is-sticky) {
                .nav-item {
                    button {
                        &:focus {
                            border: rem(2) solid $white;
                        }
                    }
                }
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, "xl")) {
            .nav-item {
                &:hover > a,
                &:hover button svg,
                &:focus button svg,
                button:hover svg,
                button:focus svg,
                & > ul .nav-item a:hover,
                & > ul .nav-item a:focus {
                    color: $itemColour;
                }

                button {
                    svg {
                        color: $charcoal;
                    }

                    &:focus {
                        border: rem(2) solid $itemColour;
                    }
                }

                &.current-item > a {
                    color: $itemColour;

                    & ~ button svg {
                        color: $itemColour;
                    }

                    &:after {
                        width: 100%;
                    }
                }

                a {
                    color: $charcoal;

                    &:focus,
                    &:hover {
                        color: $itemColour;
                    }

                    &:after,
                    &:before {
                        background-color: $itemColour;
                    }
                }

                & > ul .nav-item {
                    &.current-item {
                        a {
                            color: $itemColour;
                        }
                    }

                    a {
                        &:after,
                        &:before {
                            background-color: $itemColour;
                        }
                    }
                }
            }
        }
    }
}
