body.#{(map-get($languages, 'arabic'))} {

    .accordion {

        &__title {
            padding: rem(22) rem(24) rem(24) rem(60);
            text-align: right;

            svg {
                left: rem(24);
                right: inherit;
            }
        }

    }

    /*
        XLarge Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

        .accordion {

            &__title {
                padding: rem(32) rem(32) rem(32) rem(84);
            }

        }

    }

}