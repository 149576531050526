@each $bg, $item in $bg-styles {
    body.#{$bg} {
        .header {
            &__action-btn {
                background-color: $white;
                border-color: $white;
                color: $item;
            }

            &:not(.is-sticky) {
                .header__left {
                    svg,
                    a span svg {
                        color: $white;
                    }
                }

                .header__mobile-btn {
                    span {
                        background-color: $white;
                    }
                }
            }

            &__lower {
                background-color: $item;
                transition: background-color 5s ease, opacity 0.5s ease, visibility 0.6s ease, top 0.5s ease;
            }

            &:not(.is-sticky) {
                .header__language {
                    .dropdown__current,
                    ul li button {
                        color: $white;
                    }

                    ul {
                        background-color: $item;
                    }
                }
            }

            &__language {
                .dropdown__current,
                ul li button {
                    @include font-smoothing;
                }

                ul {
                    box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.31);
                }
            }

            .social ul li a svg {
                color: $white;
            }
        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .header {
                &__action-btn {
                    &:hover,
                    &:focus {
                        background-color: $item;
                        color: $white;
                    }
                }

                &:not(.is-sticky) {
                    .header__mobile-btn {
                        &:focus {
                            border-color: $white;
                        }
                    }
                }
            }
        }

        /*
            XLarge Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "xl")) {
            .header {
                &__lower {
                    background-color: transparent;
                    transition: opacity 0.5s ease, visibility 0.6s ease, top 0.5s ease;
                }

                &:not(.is-sticky) {
                    .dropdown:not(.dropdown--no-hover) ul li button,
                    li button {
                        &.active,
                        &:hover,
                        &:focus {
                            &:before {
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
