@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        $itemColour: $item;

        .accordion {
            &.active {
                .accordion__title,
                .accordion__title svg {
                    color: $charcoal;
                }
            }

            &__title {
                background-color: $itemColour;
                border-color: $white;
                color: $white;
            }

            &__content {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: $itemColour;
                }

                ul {
                    li:not([class]) {
                        &:before {
                            background-color: $itemColour;
                        }
                    }
                }
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .accordion {
                &__title {
                    &:hover,
                    &:focus {
                        background-color: $white;
                        color: $itemColour;

                        svg {
                            color: $itemColour;
                        }
                    }
                }
            }
        }
    }
}
