@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";
@import "./_lang";

.dropdown {

    width: 100%;
    position: relative;

    &.active {

        .dropdown__current {

            svg {
                transform: rotate(180deg);
            }

        }

        ul {
            opacity: 1;
            visibility: visible;
        }

    }


    button {
        @include clear-default-appearance;
        cursor: pointer;

        &:focus {
            outline: 0;
            text-decoration: underline;
        }
    }


    &__current {
        color: $charcoal;
        font-size: rem(16);
        line-height: rem(26);
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;

        svg {
            width: rem(13);
            height: rem(12);
            margin: 0 0 0 rem(7);
        }
    }

    ul {
        @include shadow;
        transition: opacity 0.4s ease, visibility 0.5s ease;
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        min-width: rem(155);
        width: auto;
        top: calc(100% + 40px);
        opacity: 0;
        visibility: hidden;
        text-align: center;
        background-color: $white;
        border-radius: rem(2);
        padding: rem(24);
        transition: background-color 0.5s ease;

        li {
            list-style: none;
            padding: 0 0 rem(20) 0;
            margin: 0;
            width: 100%;
            display: block;

            &:before {
                display: none;
            }

            &:last-child {
                padding: 0;
            }
            
            button {
                font-family: $latin-heading-font;
                font-weight: $latin-heading-font-weight;
                width: 100%;
                text-align: center;
                font-size: rem(16);
                line-height: rem(26);
                font-weight: 600;
                color: $brownish-grey;

                &.active:focus {
                    text-decoration: underline;
                }

                &:focus {
                    outline: 0;
                }
            }

        }

    }

}

/*
    Medium Screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'md')) {


    .dropdown {

        &__current {
            font-weight: 700;
            color: $charcoal;
            font-size: rem(16);
            line-height: rem(26);

            &:hover,
            &:focus {
                svg {
                    transform: rotate(180deg);
                }
            }

            svg {
                width: rem(13);
                height: rem(12);
                margin: 0 0 0 rem(7);
            }
        }

        ul {

            li {

                button {

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                    
                }

            }
            
        }


    }

}


/*
    XLarge Screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

    .dropdown {

        height: 100%;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;

        &:hover {

            .dropdown__current {

                svg {
                    transform: rotate(180deg);
                }

                & ~ ul {
                    opacity: 1;
                    visibility: visible;
                }
            }

        }

        &__current {
            font-size: rem(19);
            line-height: rem(28);

            &:focus {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        ul {
            min-width: rem(155);
            width: auto;
            padding: rem(24);
            top: 100%;

            li {

                padding: 0 0 rem(13) 0;

                button {

                    position: relative;
                    transition: padding 0.5s ease;
                    text-align: center;
                    font-size: rem(19);
                    line-height: rem(28);

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }

            }

        }

    }


    .dropdown:not(.dropdown--no-hover) {

        ul {

            li {

                button {

                    text-align: left;

                    &.active,
                    &:hover,
                    &:focus {

                        padding: 0 0 0 rem(15);

                        &:before {
                            content: "";
                            width: rem(7);
                            height: rem(3);
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: $brownish-grey;
                        }

                    }

                }

            }

        }

    }

}