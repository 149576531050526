body.#{(map-get($languages, 'arabic'))} {

    .term-block {
        padding: rem(32) rem(24) rem(56) rem(52);
    }

    /*
        Medium Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

        .term-block {
            padding: rem(60) rem(32) rem(86) rem(32);
        }

    }

}