@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_theme";
@import "./_rtl";
@import "./_lang";

body.ie-11 {

    .header {

        &__lower {
            transition: opacity 0.5s ease;
            z-index: 0;
            top: 100%;
        }
    }

}

body.region-modal-active {

    .header,
    .header.active {
        transform: translateY(-100%);
    }

}

.header {

    transition: transform 0.5s ease;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 11;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: opacity 0.5s ease, transform 0.5s ease;
        opacity: 0;
        box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.31);
        transform-origin: top left;
        background-color: white;
    }

    &.is-sticky {
        position: fixed;

        &:before {
            opacity: 1;
        }
    }

    &.hide:not(.active) {
        transform: translateY(-100%);
    }

    &.scroll {
        transition: transform 0.5s ease;
    }

    &.top {

        .header__left {

            a {

                svg {
                    width: rem(20);
                    height: rem(20);
                }

                span {
                    opacity: 1;
                    visibility: visible;
                    margin: rem(2) 0 0 0;
                    max-height: rem(27);
                    height: 100%;

                    svg {
                        width: rem(50);
                        height: rem(20);
                    }
                }
            }
        }

    }

    &.active {

        position: fixed;
        z-index: 155;

        .header__lower {
            opacity: 1;
            visibility: visible;
            height: 100%;
            z-index: 10;
            top: 0;
            
        }

        .header__menu-wrap,
        .header__actions {
            opacity: 1;
        }

        .header__mobile-btn {

            span {

                &:nth-child(1) {
                    top: rem(6);
                    transform: rotate(-45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                    visibility: hidden;
                }

                &:nth-child(3) {
                    top: rem(6);
                    transform: rotate(45deg);
                }
            }

        }

        .header__left,
        .header__centre {
            opacity: 0;
            visibility: hidden;
        }

    }

    &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: row nowrap;
        align-items: stretch;
        align-content: stretch;
        padding: rem(16) rem($padding);
        position: relative;
        z-index: 11;
    }

    &__left {

        justify-content: flex-start;
        transition: opacity 0.5s ease, width 0.4s ease;
        width: rem(50);

        a {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;

            svg {
                width: rem(30);
                height: rem(30);
                transition: width 0.6s ease, height 0.6s ease;
                transition-delay: 0.2s;
            }

            span {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                flex-flow: row wrap;
                align-items: center;
                align-content: center;
                opacity: 0;
                visibility: hidden;
                transition: all 0.6s ease;
                transition-delay: 0.2s;
                height: 0;
                overflow: hidden;
                max-height: 0;
            }
        }

        svg {
            color: $charcoal;
        }

    }

    &__left,
    &__centre,
    &__right {
        display: flex;
        align-content: center;
        align-items: center;
        flex-flow: row wrap;
    }

    &__right {
        justify-content: flex-end;
    }

    &__centre {
        justify-content: center;
        transition: opacity 0.5s ease;
    }

    &__mobile-btn {
        @include clear-default-appearance;
        width: rem(22);
        height: rem(16);
        cursor: pointer;
        display: block;
        position: relative;

        &:hover,
        &:focus {
            
            outline: 0;

            span:nth-child(2) {
                transition-delay: 0s;
            }
            
        }

        span {
            width: 100%;
            display: block;
            height: rem(3);
            background-color: $charcoal;
            border-radius: rem(5);
            position: absolute;
            left: 0;
            top: 0;
            transition: transform 0.4s ease, background-color 0.3s ease, opacity 0.4s ease;

            &:nth-child(2) {
                top: rem(7);
            }

            &:nth-child(3) {
                top: rem(14);
            }
        }
    }

    &__lower {
        transition: opacity 0.5s ease, visibility 0.6s ease;
        opacity: 0;
        visibility: hidden;
        position: fixed;
        left: 0;
        z-index: -1;
        width: 100%;
        top: 100vh;
        background-color: $white;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: rem(100) rem(20) rem(40) rem(20);
        -webkit-overflow-scrolling: touch;
    }

    &__menu-wrap {
        transition: opacity 0.9s ease;
        transition-delay: 0.6s;
        opacity: 0;
    }

    &__actions {
        padding: rem(68) 0 rem(100) 0;
        transition: opacity 0.9s ease;
        transition-delay: 0.6s;
        opacity: 0;
    }

    &__action-btn {
        @include font-smoothing;
        font-size: rem(14);
        line-height: rem(14);
        font-weight: 700;
        border-radius: rem(3);
        text-align: center;
        padding: rem(14) rem(24);
        text-decoration: none;
        display: block;
        width: 100%;
        max-width: rem(150);
        transition: background-color 0.5s ease;
        border: rem(2) solid $white;
        margin: 0 0 rem(38) 0;
        background-color: $charcoal;
        color: $white;
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;

        &:focus {
            outline: 0;
        }
    }

    .social ul li a svg {
        color: $charcoal;
    }

}

/*
    Medium Screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

    .header {

        &.active {
    
            .header__mobile-btn {
    
                span {
    
                    &:nth-child(1) {
                        top: rem(14);
                    }
    
                    &:nth-child(3) {
                        top: rem(14);
                    }
                }
    
            }
    
        }

        &.top {
            .header__left {
                
                width: rem(70);

                a {

                    svg {
                        width: rem(30);
                        height: rem(30);
                    }

                    span {
                        
                        max-height: 100%;
                        margin: rem(8) 0 0 0;
    
                        svg {
                            width: rem(70);
                            height: rem(20);
                        }
                    }
                }


            }
        }

        &__left {
            a {
                position: relative;
                transition: opacity 0.4s ease;
                opacity: 1;

                &:hover {
                    opacity: 0.7;
                }
                
                &:focus {
                    &:before {
                        content: "";
                        position: absolute;
                        left: rem(-7.5);
                        width: calc(100% + 15px);
                        border: rem(2) solid $white;
                        top: rem(-7.5);
                        height: calc(100% + 15px);
                    }
                }
            }
        }

        &__mobile-btn {

            position: relative;
            transition: opacity 0.4s ease;
            opacity: 1;
            width: rem(40);
            height: rem(35);
            border: rem(2) solid transparent;

            &:hover {
                opacity: 0.6;
            }

            &:focus {
                border-color: $charcoal;
            }

            span {
                width: rem(27);
                left: rem(4);
                top: rem(5);

                &:nth-child(2) {
                    top: rem(14);
                }

                &:nth-child(3) {
                    top: rem(23);
                }
            }

        }

    }

}

/*
    Large Screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'lg')) {

    .header {

        &.active {
    
            .header__mobile-btn {
    
                span {
    
                    &:nth-child(1) {
                        top: rem(13);
                    }
    
                    &:nth-child(3) {
                        top: rem(13);
                    }
                }
    
            }
    
        }

        &__mobile-btn {
            width: rem(41);
            height: rem(33);

            span {

                height: rem(3);
                left: rem(5);
    
                &:nth-child(2) {
                    top: rem(13);
                }
    
                &:nth-child(3) {
                    top: rem(21);
                }
            }
        }

    }

}

/*
    XLarge Screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

    .header {

        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-content: stretch;
        align-items: stretch;
        padding: rem(10) rem(25);
        transition: opacity 0.5s ease, transform 0.5s ease;
        opacity: 1;
        z-index: 11;

        &.top {

            .header__left {

                width: rem(80);

                a {

                    svg {
                        width: rem(33);
                        height: rem(33);
                    }

                    span {
                        margin: rem(8) 0 0 0;

                        svg {
                            width: rem(80);
                            height: rem(25);
                        }
                    }
                }
            }

        }

        &.active {

            .header__lower {
                height: auto;
                padding: 0;
            }

        }

        &.scroll {
            .header__lower {
                transition: opacity 0.5s ease, visibility 0.6s ease, top 0.5s ease;
            }
        }
        
        &__left {

            width: rem(80);
            // margin: 0 rem(40) 0 0;
            margin: 0 0 0 0;

            a {

                margin: 0;

                svg {
                    width: rem(48);
                    height: rem(42);
                }

            }

        }

        &__top {
            width: 30%;
            // min-width: rem(200);
            padding: 0;
        }

        &__right {
            display: none;
        }

        &__lower {
            position: relative;
            z-index: 10;
            top: 0;
            height: auto;
            padding: 0;
            opacity: 1;
            visibility: visible;
            width: 70%;
            transition: none;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;
            justify-content: flex-end;
            height: inherit;
            max-height: inherit;
            overflow-x: inherit;
            overflow-y: inherit;
            transition: none;
            background-color: transparent;
        }

        &__menu-wrap {
            width: auto;
            padding: 0;
            opacity: 1;
            display: flex;
            align-content: center;
            align-items: center;
            flex-flow: row wrap;
            visibility: visible;
            transition: none;
        }

        &__actions {
            display: none !important;
        }

    }
}

.header__language .dropdown__current {
    text-transform: uppercase !important;
    font-size: 0.8rem !important;
    font-weight: normal;
    line-height: 1.75rem;
    font-family: sans-serif;
    width: 100%;
    padding-top: 3px;
}

#b_text {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.75rem;
    font-family: sans-serif;
    text-transform: uppercase;
    min-width: max-content;
    padding-top: 3px;
}

.header:not(.is-sticky) #b_text {
    color: white;
}

@media screen and (max-width:  map-get($grid-breakpoints, 'md')) {
    .header__top .header__language .dropdown__current {
        padding-right: 20px;
    }

    #hdr_space {
        display: none !important;
    }

    .mini-caption {
        padding-left: 0px;
    }

    #grad div {
        margin-left: 3px !important;
    }

    .mini-caption .btn {
        width: 175px !important;
    }

    .header__top {
        padding-left: 3px;
        padding-right: 3px;
    }

    #b_text {
        font-size: 12px;
    }
}

.header__lower .header__language .dropdown__current {
    display: none;
}

@media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

    .header__lower .header__language .dropdown__current {
        display: block;
    }
}

.smaller-heading {
    margin-top: -30px !important;
    margin-bottom: 10px !important;
}