@each $bg, $item in $bg-styles {
    body.#{$bg}{
        
        .home-banner {

            h1 {
                color: $white;
            }
        
            &__scroll {

                &:before {
                    border-left: rem(3) solid $white;
                    border-bottom: rem(3) solid $white;
                }
        
            }
        
            &__pause-play {
                border: rem(3) solid $white;
        
                &:before,
                &:after {
                    background-color: $white;
                }
        
                &.paused {
        
                    &:before {
                        background-color: transparent;
                        border-color: transparent transparent transparent $white;
                    }

                }
        
            }

        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {

            .home-banner {

                &__pause-play {

                    &.paused {
                        &:hover,
                        &:focus {
                            &:before {
                                background-color: transparent;
                                border-color: transparent transparent transparent $item;
                            }
                        }
                    }

                    &:hover,
                    &:focus {

                        background-color: $white;

                        &:before,
                        &:after {
                            background-color: $item;
                        }

                    }
                }

                &__scroll {
                    &:focus {
                        border-color: $white;
                    }
                }
            }

        }

        
    }
}

