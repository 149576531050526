@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";

.section-intro {
    width: 100%;
    // @include component-spacing;

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    &__inner {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
        text-align: center;
        text-align: center;
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    h2 {
        margin: 0;
        padding: 0;
        color: $charcoal;
        width: 100%;
    }

    &__content {
        margin: rem(5) 0 0 0;
        font-size: rem(14);
        line-height: rem(24);
        color: $charcoal;
        font-weight: 400;
        width: 100%;
    }

    &__cta {
        margin: rem(40) 0 0 0;
        width: 100%;

        a[target="_blank"] {
            &:after {
                content: url("../../assets/img/icon-new-tab-white.svg");
            }
        }
    }

    // &--full-width {
    //     .section-intro__inner {
    //         max-width: 100%;
    //     }
    // }

    // &--yellow {
    //     .section-intro__wrapper {
    //         background-color: $golden;
    //         padding: rem(60) 0;
    //     }
    // }

    // &--grey {
    //     .section-intro__wrapper {
    //         background-color: $white-two;
    //         padding: rem(60) 0;
    //     }

    //     .section-intro__inner {
    //         max-width: 80%;
    //     }
    // }
}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    .section-intro {
        &__inner {
            max-width: map-get($container-max-widths, "sm");
        }
    }
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    .section-intro {
        &__inner {
            max-width: map-get($container-max-widths, "md");
        }

        // &--grey,
        // &--yellow {
        //     .section-intro__wrapper {
        //         padding: rem(80) 0;
        //     }
        // }

        // &--grey {
        //     .section-intro__inner {
        //         max-width: 70%;
        //     }
        // }
    }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .section-intro {
        &__inner {
            max-width: map-get($container-max-widths, "lg");
        }

        &__content {
            font-size: rem(16);
            line-height: rem(26);
            margin: rem(15) 0 0 0;
        }

        &__cta {
            margin: rem(40) 0 0 0;
        }

        // &--full-width {
        //     .section-intro__inner {
        //         max-width: 100%;
        //     }
        // }

        // &--grey,
        // &--yellow {
        //     .section-intro__wrapper {
        //         padding: rem(160) 0;
        //     }
        // }

        // &--grey {
        //     .section-intro__inner {
        //         max-width: rem(745);
        //     }
        // }
    }
}
