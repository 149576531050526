// create bg styles
@each $bg, $item in $bg-styles {

    body.#{$bg}{
        .social {
            ul li svg {
                color: $white;
            }
        }


        @media screen and (min-width:  map-get($grid-breakpoints, 'md')) {
            .social {
                ul li a {

                    &:focus {
                        border: rem(2) solid $white;
                    }

                }
            }
        }

    }

}