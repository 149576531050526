body.#{(map-get($languages, 'arabic'))} {
    
    .dropdown {
        
        &__current {
            display: flex;
            justify-content: flex-start;
            flex-flow: row wrap;
            align-content: center;
            align-items: center;

            svg {
                margin: 0 rem(7) 0 0;
            }
        }

    }

    /*
        XLarge Screens
    */

    @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

        .dropdown:not(.dropdown--no-hover) {

            ul {

                li {

                    button {

                        text-align: right;

                        &.active,
                        &:hover,
                        &:focus {

                            padding: 0 rem(15) 0 0;

                            &:before {
                                right: 0;
                                left: inherit;
                            }

                        }

                    }

                }

            }

        }
    }

}