@each $theme, $item in $template-themes {

    body.#{$theme}{

        $themeColour: $item;
        
        .content-card {

            h6 {
                color: $themeColour;
            }

            &__icon {
                border-color: $themeColour;

                svg {
                    color: $themeColour;
                }
            }

            &--no-label {

                a {
                    border-color: $themeColour;
                }

            }

        }

        @media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

            .content-card {

                a:hover,
                a:focus {
                    .content-card__icon {
                        background-color: $themeColour;
                    }
                }

            }

        }

    }

}