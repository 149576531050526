@each $bg, $item in $bg-styles {
    body.#{$bg}{

        .footer{

            &__wrapper {
                background-color: transparent;
            }

            &__links {

                ul li a,
                ul li {
                    @include font-smoothing;
                    color: $white;
                }

                ul li a:after {
                    background-color: $white;
                }

            }

            .social li svg,
            &__copyright,
            &__unfe svg,
            &__stand-up svg {
                @include font-smoothing;
                color: $white;
            }

            &__logos,
            .social li a:focus {
                border-color: $white;
            }

        }


        /* 
            Medium screens
        */
        @media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

            .footer {

                &__stand-up,
                &__unfe {

                    a {

                        &:focus {
                            border: rem(4) solid $item;
                            box-shadow: 0 0 0 rem(2) $white;
                        }
                    }
                }
            }
        }


    }
}