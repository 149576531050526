@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";
@import "./_lang";
@import "./_comp-bg";

.figure-block {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
    flex-flow: row wrap;

    &__value {
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;
        width: 100%;
        text-align: center;
        color: $black-two;
        margin: 0 0 rem(10) 0;
    }

    &__content {
        text-align: center;
        color: $charcoal;
        font-size: rem(14);
        line-height: rem(24);
        width: 100%;
        max-width: 90%;
    }

}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .figure-block {

        &__value {
            margin: 0 0 rem(18) 0;
        }

        &__content {
            font-size: rem(16);
            line-height: rem(26);
            max-width: 95%;
        }

    }

}

/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .figure-block {

        &__value {
            margin: 0 0 rem(18) 0;
        }

        &__content {
            max-width: 85%;
            padding: 0 rem(20);
        }

    }

}