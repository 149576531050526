@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";
@import "./_theme";
@import "./_lang";

body.regional-modal-active {
    .side-bar {
        transform: translateY(100%);    
    }
}

.side-bar {

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: transform 0.5s ease;
    transform: translateY(0);

    &.hide {
        transform: translateY(100%);
    }

    &.focus {
        transform: translateY(0) !important;
    }

    .social {
        display: none;

        a {
            pointer-events: none;
        }
    }

    &__action {
        width: 100%;

        button {
            @include clear-default-appearance;
            @include font-smoothing;
            font-family: $latin-heading-font;
            font-weight: $latin-heading-font-weight;
            display: block;
            width: 100%;
            text-align: center;
            font-size: rem(14);
            line-height: rem(24);
            font-weight: 700;
            text-decoration: none;
            padding: rem(19) 0;
            color: $red-wine;
            background-color: $white;
            border-radius: rem(2);
            box-shadow: 0 rem(-1) rem(7) 0 rgba($black, 0.36);
            cursor: pointer;
            border: rem(3) solid $charcoal;
            transition: background-color 0.4s ease;

            &.active {
                &:before {
                    transform: scale(100);
                    opacity: 1;
                }
            }
            
            &:before {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                border-radius: rem(999);
                left: 100%;
                bottom: 80%;
                transition: transform 0.5s ease, opacity 0.3s ease;
                opacity: 0;
            }

            &:focus {
                outline: 0;
            }

        }
    }
    
}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    
    .side-bar {
    
        &__action {

            button {
                &:focus,
                &:hover {
                    background-color: $white;
                    color: $charcoal;
                    border-color: $charcoal;
                }
            }
        }
    }
}

/*
    Large Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    body.regional-modal-active {
        .side-bar {
            transform: translate(0, 0);
            left: -100%;
            bottom: 10%;
        }
    }

    .side-bar {

        bottom: 10%;
        width: auto;
        padding: 0 0 rem(195) 0;
        transition: left 1s ease;
        transform: translate(0, 0);
        left: 0;
        z-index: 15;

        &.hide {
            transform: translate(0, 0);
            left: -100%;
            bottom: 10%;
        }

        &.focus {
            transform: translate(0, 0) !important;
            left: 0 !important;
            transition: none !important;
        }

        .social {
            display: block;
            width: rem(54);

            ul {
                justify-content: center;
            }

            a {
                pointer-events: all;
            }
        }

        &__action {
            transform: rotate(-90deg);
            position: absolute;
            left: 0;
            bottom: 0;

            button {
                width: rem(174);
                font-size: rem(19);
                line-height: rem(28);
                padding: rem(15) 0;
            }
        }

    }

}