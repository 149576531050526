body.#{(map-get($languages, 'arabic'))} {

    .social-content-col {

        &__icons {

            .social {

                width: 100%;

                ul {

                    li {
                        margin: 0 0 0 rem(16);
                    }
                }
            }
        }

    }

    /*
        Medium Screens
    */

    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {

        .social-content-col {

            &__icons {

                .social {
        
                    ul {
        
                        li {
                            margin: 0 0 0 rem(35);
                        }
                    }
                }

            }

        }

    }

    /*
        XLarge Screens
    */

    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

        .social-content-col {

            &__icons {
                order: 2;
            }

            &__icons {

                .social {
        
                    ul {
        
                        li {
                            margin: 0 0 rem(43) rem(83);

                            &:nth-child(2){
                                margin-left: 0;
                            }

                            &:last-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

}