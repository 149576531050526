@each $bg, $item in $bg-styles {

    body.#{$bg}{
        .colour-card {

            &__icon {
                border-color: $white;

                svg {
                    color: $white;
                }
            }

            h3,
            p {
                @include font-smoothing;
                color: $white;
            }
    
        }
    }

}