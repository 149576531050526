// create bg styles
@each $bg, $item in $bg-styles {
    body.#{$bg} {
        /*
            XLarge Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "xl")) {
            .header:not(.is-sticky) {
                .nav {
                    ul li.nav-item > ul {
                        background-color: $item;
                    }
                }
            }
        }
    }
}
