@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";

$delay: 0.4;
$limit: 10;

.img-group {

    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;
    padding-left: rem($padding);
    padding-right: rem($padding);

    &__item {
        width: 100%;
        margin: 0 0 rem(12) 0;
        height: auto;

        &:last-child {
            margin: 0;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

}

/*
    Small Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .img-group {
        max-width: 65%;
    }

}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .animated {
        .img-group {

            &__item {
                transition: opacity 0.8s ease, visibility 1s ease, transform 1s;
                transition-delay: 0.8s;
                opacity: 0;
                visibility: hidden;
                transform: translateY(20%);
            }

        }
    }

    .is-visible {
        .img-group {

            &__item {
                
                opacity: 1;
                visibility: visible;
                transform: translateY(0);

                @for $i from 1 through $limit {
                    &:nth-child(#{$i}) {
                        transition-delay: ($delay * $i) + s;
                    }
                }
            }

        }
    }

    .img-group {
        width: 100%;
        max-width: 80%;
        justify-content: flex-start;
        flex-flow: column nowrap;
        align-content: flex-start;
        align-items: flex-start;

        &__item {
            width: 50%;
            max-width: 100%;
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;

            &:first-child {
                width: 50%;
                display: flex;
                align-items: flex-end;
                align-content: flex-end;
                justify-content: flex-start;
                margin: 0 0 rem(10) 0;

                & > div {
                    max-width: 80%;
                }
            }

            &:nth-child(2){
                width: 50%;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: flex-end;
                padding: 0 rem(20) 0 0;

                & > div {
                    max-width: 95%;
                }
            }

            &:last-child {
                margin: rem(10) 0 0 0;
            }

            &:nth-child(2){
                display: flex;
                justify-content: flex-end;
                align-items: center;
                align-content: center;
            }

            &:nth-child(even){
                align-self: flex-start;
            }

            &:nth-child(odd){
                align-self: flex-end;
            }

        }

    }

}


/*
    Large Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .img-group {

        padding-left: 0;
        padding-right: 0;
        max-width: rem(940);

    }


}