body.#{(map-get($languages, 'arabic'))} {

    /*
        Large Screens
    */

    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        
        .map {

            &__locations {
        
                button {

                    padding: rem(8) rem(30) rem(8) rem(15);

                    svg {
                        right: rem(10);
                        left: inherit;
                    }
                }

            }

            &__info {
                flex-direction: row row-reverse;

                svg {
                    margin: 0 rem(11) 0 0;
                    order: 2;
                }
            }
            
        }

    }

    /*
        XLarge Screens
    */

    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
        
        .map {

            &__locations {
        
                button {

                    padding: rem(11) rem(35) rem(11) rem(15);

                    svg {
                        right: rem(10);
                        left: inherit;
                    }
                }

            }
            
        }

    }

}