@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_rtl";
@import "./_lang";

.region-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: $white;
    z-index: 60;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &__header {
        position: relative;
        width: 100%;
        margin: 0 0 rem($component-spacing-xs) 0;
    }

    &__close {
        @include clear-default-appearance;
        position: absolute;
        right: 0;
        top: 0;
        width: rem(18);
        height: rem(18);
        cursor: pointer;
        top: rem(22);
        right: rem(20);
        z-index: 1;

        &:focus {
            outline: 0;
        }

        &:before,
        &:after {
            content: "";
            width: rem(18);
            height: rem(2);
            display: block;
            position: absolute;
            left: 0;
            background-color: $white;
            top: rem(8);
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &__img {
        margin: 0 0 rem(30) 0;
        height: rem(180);
        position: relative;
        overflow: hidden;
        border-radius: rem(2);
    }

    &__title {
        @include font-smoothing;
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;
        width: 100%;
        background-color: $charcoal;
        text-align: center;
        color: $white;
        padding: rem(20) rem(55);
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;

        &-inner {
            padding: 0 rem($padding);
            width: 100%;
            max-width: rem(550);
        }
    }

    &__text {
        p {
            font-size: rem(14);
            line-height: rem(24);
            padding: rem(10) 0;
            margin: 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &__sections {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: $white-two;
        position: relative;
        z-index: 2;

        &-inner {
            padding: 0 rem($padding);
            width: 100%;
            max-width: rem(550);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__dropdown.dropdown {
        width: auto;
        padding: rem(20) 0;

        ul {
            top: 100%;
            min-width: rem(200);
        }
    }

    &__region-title {
        display: flex;
        align-content: center;
        align-items: center;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 0 0 rem(12) 0;

        h5 {
            display: block;
            margin: 0;
            width: auto;
            color: $charcoal;
            padding: 0;
            width: 100%;
        }

        h6 {
            font-family: $latin-body-font;
            font-weight: $latin-body-font-weight;
            margin: rem(10) 0 rem(15) 0;
            padding: 0;
            display: block;
            width: 100%;
        }
    }

    &__controls {
        width: 100%;
        padding: rem(40) 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;
        justify-content: flex-start;

        span {
            display: inline-block;
            margin: 0 0 0 rem(20);
            font-size: rem(14);
            line-height: rem(14);
        }
    }

    &__prev {
        margin: 0 rem(6) 0 0;
    }

    &__next {
        margin: 0 0 0 rem(6);
    }

    &--icon {
        .region-modal {
            &__header {
                margin: 0 0 rem(30) 0;
            }

            &__region-title {
                h5,
                h6 {
                    width: 100%;
                }

                h6 {
                    margin: rem(10) 0 rem(15) 0;
                }
            }

            &__img {
                width: rem(70);
                height: rem(70);
                margin: 0 0 rem(12) 0;
                border-radius: 0;
            }

            &__content {
                &-inner {
                    max-width: rem(745);
                    position: relative;
                }
            }
        }
    }
}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    .region-modal {
        &__header {
            margin: 0 0 rem($component-spacing-md) 0;
        }

        &__img {
            margin: 0 0 rem(30) 0;
            height: rem(280);
            position: relative;
            overflow: hidden;
            border-radius: rem(2);
        }

        &__close {
            transition: transform 0.4s ease;

            &:hover,
            &:focus {
                transform: rotate(180deg);
            }
        }
    }
}

/*
    Large Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .region-modal {
        &__header {
            margin: 0 0 rem($component-spacing-lg) 0;
        }

        &__img {
            margin: 0 0 rem(24) 0;
            height: rem(300);
        }

        &__region-title {
            margin: 0 0 rem(20) 0;
        }

        &__title {
            padding: rem(30) rem(65);
        }

        &__dropdown.dropdown {
            padding: rem(24) rem(24);
        }

        &__close {
            width: rem(36);
            height: rem(36);
            top: rem(26);
            right: rem(25);

            &:before,
            &:after {
                width: rem(30);
                height: rem(3);
                top: rem(17);
                left: rem(3);
            }
        }

        &__text {
            p {
                padding: rem(12) 0;
            }
        }

        &__controls {
            padding: rem(70) 0;
        }

        &--icon {
            .region-modal {
                &__header {
                    margin: 0;
                }

                &__content {
                    padding: rem(120) 0;
                }

                &__img {
                    width: rem(142);
                    height: rem(142);
                    margin: 0;
                    position: absolute;
                    left: rem($padding);
                    top: 0;
                }

                &__region-title,
                &__text {
                    padding: 0 0 0 rem(190);
                }

                &__region-title {
                    margin: 0;

                    h6 {
                        margin: rem(10) 0 rem(30) 0;
                    }
                }

                &__controls {
                    padding: rem(60) 0 0 rem(190);
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}

/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .region-modal {
        &__header {
            margin: 0 0 rem($component-spacing-xl) 0;
        }
    }
}
