@each $bg, $item in $bg-styles {
    body.#{$bg}{
        .instagram {

            &__left {

                h3,
                h5 {
                    color: $white;
                }
                
            }

            &__platforms {

                p {
                    color: $white;
                }

            }

            .carousel {
    
                &__nav svg {
                    color: $item;
                }
        
            }
            
        }
    }
}