@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_mixins";
@import "./_rtl";
@import "./_lang";

.campaign-card {

    display: block;
    width: 100%;
    text-decoration: none;
    box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.07);
    overflow: hidden;
    border-radius: rem(2);
    background-color: $white;

    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
    }

    &__img {
        position: relative;
        width: 100%;
        height: rem(185);
    }

    &__lower {
        border-top: rem(4) solid $charcoal;
        background-color: $white;
        padding: rem(40) rem(20);
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        overflow: hidden;
    }

    h4 {
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 0;
        color: $charcoal;
    }

    &__content {
        width: 100%;
        margin: rem(12) 0 rem(30) 0;
        text-align: center;
        color: $charcoal;
    }

    p {
        color: $charcoal;
        margin: 0;
        padding: 0;
    }

    &__icon {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;

        span {
            width: rem(26);
            height: rem(26);
            background-color: $charcoal;
            border-radius: rem(999);
            display: block;
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;
        }

        svg {
            color: $white;
            width: rem(12);
            height: rem(12);
            transition: transform 0.4s ease;
            transform: rotate(-90deg);
        }
    }

    &--purple {
        @include campaign-theme($darkish-purple);
    }

    &--orange {
        @include campaign-theme($sienna);
    }

    &--blue {
        @include campaign-theme($bluish);
    }

    &--red {
        @include campaign-theme($red-wine);
    }

    &--green {
        @include campaign-theme($spruce);
    }

    &--feature {

        h4,
        p {
            @include font-smoothing;
            color: $white;
        }

        .campaign-card {

            &__lower {
                border-color: $darkish-purple;
                background-color: $darkish-purple;
            }

            &__content {
                color: $white;
            }

            &__icon {

                span {
                    background-color: $white;
                }

                svg {
                    color: $darkish-purple;
                }
            }

        }

    }

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .campaign-card {

        h4,
        &__content {
            max-width: 100%;
        }

        &__img {
            position: relative;
            width: 100%;
            height: rem(200);
        }
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .campaign-card {

        position: relative;
        transition: opacity 0.4s ease;

        &:hover,
        &:focus {
            outline: 0;
            opacity: 0.8;
        }

        &--large {

            min-height: rem(634);

            .campaign-card {
                &__img {
                    height: 100%;
                }

                &__lower {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                }
            }
        }

    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .campaign-card {

        h4 {
            margin: 0 0 rem(10) 0;
        }

        &:hover,
        &:focus {

            .campaign-card__icon svg {
                transform: rotate(-90deg);
            }

        }

        &__content {
            margin: rem(12) 0;
        }

        &__icon {

            margin: rem(10) 0 0 0;
        
            svg {
                transform: rotate(0);
            }
        }

        &__lower {
            padding: rem(24);
        }

        &--large {

            display: flex;
            align-content: stretch;
            align-items: stretch;
            flex-flow: row wrap;
            justify-content: center;

            .campaign-card {

                &:hover,
                &:focus {
                    
                    .campaign-card__content {
                        margin: rem(20) 0 rem(30) 0;
                    }

                }

                &__lower {
                    padding: rem(25);
                }

                &__icon {
                    
                    span {
                        width: rem(38);
                        height: rem(38);
                    }

                    svg {
                        width: rem(14);
                        height: rem(14);
                    }

                }
            }
        }


    }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .campaign-card {

        min-height: rem(380);
        transition: none;

        &:hover,
        &:focus {

            opacity: 1;

            .campaign-card__content {
                opacity: 1;
                visibility: visible;
                margin: 0 0 rem(10) 0;
            }

        }

        &__icon {
            margin: rem(10) 0 0 0;
        }

        &__lower {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            min-height: rem(128);
            align-items: flex-start;
            align-content: flex-start;
        }

        &__img {
            height: 100%;
        }

        &__content {
            margin: 0 0 rem(-200) 0;
            opacity: 0;
            visibility: hidden;
            transition: visibility 0.6s ease, margin 0.6s ease, opacity 0.6s ease;
            font-size: rem(14);
            line-height: rem(24);
        }

        &--large {

            &:hover,
            &:focus {

                opacity: 1;
                
                .campaign-card__content {
                    margin: 0 0 rem(10) 0;

                }

            }

            .campaign-card__content {
                max-width: 80%;
            }

            .campaign-card__icon {
                margin: rem(20) 0 0 0;
            }
        }

    }

}