@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.bg-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;

    &--absolute {
        position: absolute;
        left: 0;
        top: 0;
    }
}