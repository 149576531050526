@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.term-block-row {

    width: 100%;
    background-color: $white-two;
    padding: rem(60) 0;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;

    &__inner {
        width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: stretch;
        align-content: stretch;
    }

    &__item {
        width: 100%;
        margin: 0 0 rem(12) 0;

        &:last-child {
            margin: 0;
        }
    }

    .toolbar {
        margin: rem(40) 0 0 0;
    }

    @include centre-align-container;

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .term-block-row {

        padding: rem(80);

        &__inner {
            justify-content: space-between;
        }

        &__item {
            width: 31.66666%;
            margin: 0;
        }
        
    }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .term-block-row {

        padding: rem(160) 0;

        &__item {
            width: 32%;
        }

        .toolbar {
            margin: rem(60) 0 0 0;
        }
        
    }

}