@each $theme, $item in $template-themes {

    body.#{$theme}{

        $themeColour: $item;

        .carousel {

            .social {
                li svg {
                    color: $themeColour;
                }
            }

        }
    }

}