body.#{(map-get($languages, 'arabic'))} {
    
    .region-modal {

        &__region-title {

            h6 {
                margin: 0 rem(12) 0 0;
            }

        }

        &__prev {
            margin: 0 0 0 rem(6);
        }

        &__next {
            margin: 0 rem(6) 0 0;
        }

        &__controls {

            span {
                margin: 0 rem(20) 0 0;
            }
        }

    }

    /*
        Large Screens
    */

    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

        .region-modal {

            &__region-title {
                h6 {
                    margin: 0 rem(20) 0 0;
                }
            }

        }
    }

}