@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_comp-bg";

.youtube-video {

    // @include component-spacing;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding-left: rem($padding);
    padding-right: rem($padding);

    &__wrapper {
        position: relative;
        width: 100%;
        padding: 0 0 56.25% 0;
        transition: opacity 0.6s ease, visibility 0.8s ease;
        opacity: 0;
        visibility: hidden;
    
        &.loaded {
            opacity: 1;
            visibility: visible;

            .youtube-video__bg button {
                opacity: 1;
                visibility: visible;
            }
        }

        &.active {

            .youtube-video__bg {
                opacity: 0;
                visibility: hidden;
            }
    
            .youtube-video__iframe {
                opacity: 1;
                visibility: visible;
            }
    
        }
    }


    &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: 105%;
        background-position: center center;
        border-radius: rem(2);

        button {
            @include clear-default-appearance;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s ease, visibility 0.6s ease;
            width: rem(55);
            height: rem(55);
            position: absolute;
            background-color: $white;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            border-radius: rem(999);
            cursor: pointer;
            transition: background-color 0.4s ease;

            &:focus {
                outline: 0;
            }

            svg {
                color: $charcoal;
                width: rem(20);
                height: rem(20);
                margin: rem(3) 0 0 rem(3);
            }
        }
    }

    &__iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, visibility 0.6s ease;
    }

    &--legacy {

        .youtube-video {

            &__wrapper {
                padding: 0;
                height: rem(180);
            }

        }

    }

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {


    .youtube-video {

        padding-left: 0;
        padding-right: 0;
    
        &__wrapper {
            max-width: rem(465);
            height: rem(265);
            padding: 0;
        }

        &--legacy {

            .youtube-video {
    
                &__wrapper {
                    padding: 0;
                    height: rem(280);
                }
    
            }
    
        }

    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .youtube-video {

        &__bg button {
            
            &:focus,
            &:hover {
                background-color: $charcoal;

                svg {
                    color: $white;
                }
            }

        }

    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {


    .youtube-video {
    
        &__wrapper {
            max-width: rem(745);
            height: rem(420);
            max-height: 100%;
        }

        &--legacy {

            .youtube-video {
    
                &__wrapper {
                    height: rem(420);
                }
    
            }
    
        }

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .youtube-video {

        &__bg {

            button {

                width: rem(65);
                height: rem(65);

                svg {
                    width: rem(22);
                    height: rem(22);
                    margin: rem(3) 0 0 rem(3);
                }

            }
        }

        
    }


}