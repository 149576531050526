@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .download-card {
            &__details svg,
            h6 {
                color: $itemColour;
            }

            .download-card-button {
                border-color: $itemColour;
                color: $itemColour;

                svg {
                    color: $itemColour;
                }
            }

            &:hover,
            &:focus {
                .download-card-button {
                    background-color: $itemColour;
                    color: $white;

                    svg {
                        color: $white;
                    }
                }
            }
        }
    }
}
