body.#{(map-get($languages, 'arabic'))} {

    .nav-item {

        a {
            display: block;

            &:after {
                right: 0;
                left: inherit;
            }

            & ~ button {
                order: 1;
            }

            & ~ button ~ ul {
                order: 3;
            }

        }

        & > ul .nav-item a {

            padding: 0 rem(15) rem(3) 0;

            &:after {
                right: rem(15);
                left: inherit;
            }
            
            &:before {
                left: inherit;
                right: 0;
            }
        }

    }


    /*
        XLarge Screens
    */

    @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

        .nav-item {

            button {
                margin: 0 rem(5) 0 0;
            }

            & > ul {

                left: rem(10);

                .nav-item {
                    a {
                        
                        padding: 0;

                        &:hover,
                        &:focus {
                            padding: 0 rem(15) 0 0;
                        }
                    }

                }
            }

            &:last-child,
            &:nth-last-child(2) {
                & > ul {
                    left: rem(10);
                    right: inherit;
                }
            }

            &:last-child {
                padding: rem(24) rem(25) rem(24) 0;
            }
        }

    }

}