@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_comp-bg";

.wysiwyg {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    &__inner {
        width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    &__content {
        > * {
            &:first-child {
                padding-top: 0;
                margin-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    &--full-width {
        .wysiwyg__inner {
            max-width: 100%;
        }
    }

    &--no-padd {
        .wysiwyg__inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    .wysiwyg {
        &__inner {
            max-width: rem(465);
        }

        &--full-width {
            .wysiwyg__inner {
                max-width: 100%;
            }
        }
    }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .wysiwyg {
        &__inner {
            max-width: rem(745);
        }

        &--full-width {
            .wysiwyg__inner {
                max-width: 100%;
            }
        }
    }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    .wysiwyg {
        &--full-width {
            .wysiwyg__inner {
                max-width: 100%;
            }
        }
    }
}
