@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";
 

.nav-accessibility {
    z-index: 150;
    position: fixed;
    top: -50%;
    left: rem(15);
    @include font-smoothing;
    font-family: $latin-heading-font;
    font-weight: $latin-heading-font-weight;
    font-size: rem(14);
    line-height: rem(14);
    border-radius: rem(2);
    text-align: center;
    padding: rem(11) rem(20);
    display: inline-block;
    width: auto;
    text-decoration: none;
    background-color: $charcoal;
    color: $white;
    border: rem(3) solid $charcoal;
    transition: top 0.5s ease;

    &:focus,
    &:hover {
        outline: 0;
        top: rem(15);
        text-decoration: underline;
        transform: translateY(0);
    }

}