@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";

.term-block {

    width: 100%;
    padding: rem(32) rem(52) rem(56) rem(24);
    background-color: $white;
    height: 100%;

    h4 {
        padding: 0 0 rem(12) 0;
        margin: 0;
    }

    p {
        margin: 0;
        padding: 0;
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .term-block {

        padding: rem(60) rem(32) rem(86) rem(32);

        h4 {
            padding: 0 0 rem(8) 0;
        }


    }

}