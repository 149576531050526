@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.static-embed {

    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;

    &__inner {
        width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    &__wrapper {
        position: relative;
        width: 100%;
        height: rem(820);
        overflow: hidden;
    }

    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

}


/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .static-embed {

        &__inner {
            max-width: map-get($container-max-widths, 'sm');
        }

        &__wrapper {
            height: rem(700);
        }

    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .static-embed {

        &__inner {
            max-width: map-get($container-max-widths, 'md');
        }

        &__wrapper {
            height: rem(830);
        }

    }


}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .static-embed {

        &__inner {
            max-width: map-get($container-max-widths, 'lg');
        }
        

        &__wrapper {
            height: rem(810);
        }

        
    }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .static-embed {

        &__inner {
            max-width: map-get($container-max-widths, 'xl');
        }

        &__wrapper {
            height: rem(930);
        }

    }


}