@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_theme";
@import "./_rtl";
@import "./_lang";

.footer {
    width: 100%;
    margin: rem($component-spacing-xs) 0 0 0;
    position: relative;
    z-index: 1;
    transition: opacity 0.4s ease, visibility 0.6s ease;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &__shape {
        position: absolute;
        z-index: 0;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__wrapper {
        padding: rem(60) 0 rem(100) 0;
        background-color: transparent;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
    }

    &.loaded {
        opacity: 1;
        visibility: visible;
    }

    &__inner {
        width: 100%;
        padding: 0 rem($padding);
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
    }

    &__top-left,
    &__lower {
        width: 100%;
    }

    &__lower {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
        position: relative;
        z-index: 1;
    }

    &__copyright {
        order: 2;
        width: 100%;
    }

    &__links {
        width: 100%;
        margin: 0 0 rem(40) 0;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            text-align: center;

            li {
                list-style: none;
                margin: 0 0 rem(20) 0;
                padding: 0;
                font-size: rem(16);
                line-height: rem(26);
                font-family: $latin-heading-font;
                font-weight: $latin-heading-font-weight;

                &:before {
                    display: none;
                }

                &:last-child {
                    margin: 0;
                }

                a {
                    font-size: rem(16);
                    line-height: rem(26);
                    font-weight: 700;
                    text-decoration: none;
                    color: $charcoal;

                    &:hover,
                    &:focus {
                        outline: 0;
                    }
                }
            }
        }
    }

    .social {
        margin: 0 0 rem(30) 0;

        ul {
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;
        }
    }

    &__logos {
        padding-top: rem(35);
        padding-bottom: rem(40);
        border-top: rem(1) solid $charcoal;
        display: flex;
        align-items: center;
        align-content: center;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
    }

    &__unfe {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin: 0 rem(26) 0 0;

        svg {
            width: rem(110);
            height: rem(37);
        }
    }

    &__stand-up {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        svg {
            width: rem(67);
            height: rem(40);
        }
    }

    &__copyright {
        order: 2;
        text-align: center;
        font-size: rem(12);
        line-height: rem(22);
        font-weight: 400;

        p {
            font-size: rem(12);
            line-height: rem(22);
            font-weight: 400;
            margin: 0;
            padding: 0;
        }
    }
}

/* 
    Small screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    .footer {
        &__inner {
            max-width: map-get($container-max-widths, "sm");
        }
    }
}

/* 
    Medium screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    .footer {
        margin: rem($component-spacing-md) 0 0 0;

        &__inner {
            max-width: map-get($container-max-widths, "md");
        }

        &__stand-up,
        &__unfe {
            a {
                &:focus {
                    outline: 0;
                    border: rem(3) solid $white;
                    box-shadow: 0 0 0 rem(4) $charcoal;
                }

                &:hover {
                    svg {
                        opacity: 0.7;
                    }
                }
            }

            svg {
                transition: opacity 0.4s ease;
            }
        }

        &__unfe {
            svg {
                width: rem(140);
                height: rem(47);
            }
        }

        &__stand-up {
            svg {
                width: rem(105);
                height: rem(55);
            }
        }

        &__links ul li a {
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}

/* 
    Large screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .footer {
        &__wrapper {
            padding: rem($component-spacing-lg) 0;
        }

        &__inner {
            max-width: map-get($container-max-widths, "lg");
        }

        &__links {
            ul {
                display: flex;
                justify-content: flex-start;
                flex-flow: row wrap;
                align-items: center;
                align-content: center;

                li {
                    width: auto;
                    margin: 0 rem(25) 0 0;

                    a {
                        position: relative;
                        display: inline-block;
                        width: auto;
                        padding: 0 0 rem(4) 0;
                        overflow: hidden;

                        &:after {
                            content: "";
                            position: absolute;
                            left: rem(-2);
                            bottom: 0;
                            width: 0;
                            height: rem(3);
                            background-color: $charcoal;
                            transition: width 0.4s ease;
                        }

                        &:hover,
                        &:focus {
                            text-decoration: none;

                            &:after {
                                left: 0;
                                width: 100%;
                            }
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        .social {
            margin: 0 0 rem(80) 0;

            ul {
                justify-content: flex-start;
            }
        }

        &__copyright {
            order: 0;
            text-align: left;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
            width: 50%;
            padding: 0;

            p {
                width: 100%;
            }
        }

        &__logos {
            border-top: none;
            padding-top: 0;
            padding-bottom: 0;
            width: 50%;
            justify-content: flex-end;
        }

        &__unfe {
            justify-content: flex-end;
            margin: 0 rem(32) 0 0;
            width: rem(140);

            svg {
                width: 100%;
                height: rem(47);
            }
        }

        &__stand-up {
            justify-content: flex-end;

            svg {
                width: rem(100);
                height: rem(55);
            }
        }
    }
}

/* 
    XLarge screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    .footer {
        margin: rem($component-spacing-xl) 0 0 0;

        &__wrapper {
            padding: rem($component-spacing-xl) 0;
        }

        &__inner {
            max-width: map-get($container-max-widths, "xl");
        }

        &__links {
            margin: 0 0 rem(40) 0;

            ul {
                li {
                    margin: 0 rem(30) 0 0;
                    font-size: rem(19);
                    line-height: rem(21);

                    a {
                        font-size: rem(19);
                        line-height: rem(21);
                        padding: 0 0 rem(8) 0;
                    }
                }
            }
        }

        .social {
            margin: 0 0 rem(120) 0;
        }

        &__copyright {
            font-size: rem(14);
            line-height: rem(24);

            p {
                font-size: rem(14);
                line-height: rem(24);
            }
        }

        &__unfe {
            svg {
                width: rem(175);
                height: rem(60);
            }
        }

        &__stand-up {
            svg {
                width: rem(125);
                height: rem(55);
            }
        }
    }
}
