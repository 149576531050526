@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_comp-bg";
@import "./_rtl";

.img-title-content-card {
    width: 100%;

    a {
        @include shadow;
        display: block;
        width: 100%;
        border-radius: rem(2);
        overflow: hidden;
        background-color: $white;

        &[target="_blank"] {
            &:after {
                display: none;
            }
        }
    }

    &__icon {
        position: absolute;
        right: rem(24);
        top: rem(24);
        display: inline-block;
        width: rem(26);
        height: rem(26);
        border-radius: rem(999);
        border: rem(3) solid $charcoal;
        transition: background-color 0.4s ease;

        svg {
            color: $charcoal;
            width: rem(13);
            height: rem(13);
            transform: rotate(-90deg) translate(50%, -50%);
            left: 50%;
            top: 50%;
            position: absolute;
            transition: transform 0.4s ease;
        }
    }

    a {
        text-decoration: none;
        border-bottom: rem(4) solid $charcoal;
    }

    &__img {
        width: 100%;
        height: 50vh;
        max-height: rem(186);
        background-color: $very-light-pink;
        display: none;
        position: relative;
    }

    &__content {
        padding: rem(24);
        width: 100%;
        // border-bottom: rem(4) solid $charcoal;
        position: relative;
    }

    h4 {
        color: $charcoal;
        margin: 0;
        padding: 0 rem(36) 0 0;
    }

    p {
        padding: 0;
        margin: 0;
        color: $charcoal;
        display: none;
    }

    &--theme-orange {
        .img-title-content-card__content {
            border-color: $sienna;
        }

        .img-title-content-card__icon {
            border-color: $sienna;

            svg {
                color: $sienna;
            }
        }

        a {
            border-color: $sienna;
        }
    }

    &--theme-green {
        .img-title-content-card__content {
            border-color: $spruce;
        }

        .img-title-content-card__icon {
            border-color: $spruce;

            svg {
                color: $spruce;
            }
        }

        a {
            border-color: $spruce;
        }
    }

    &--theme-red {
        .img-title-content-card__content {
            border-color: $red-wine;
        }

        .img-title-content-card__icon {
            border-color: $red-wine;

            svg {
                color: $red-wine;
            }
        }

        a {
            border-color: $red-wine;
        }
    }

    &--theme-purple {
        .img-title-content-card__content {
            border-color: $darkish-purple;
        }

        .img-title-content-card__icon {
            border-color: $darkish-purple;

            svg {
                color: $darkish-purple;
            }
        }

        a {
            border-color: $darkish-purple;
        }
    }

    &--theme-blue {
        .img-title-content-card__content {
            border-color: $bluish;
        }

        .img-title-content-card__icon {
            border-color: $bluish;

            svg {
                color: $bluish;
            }
        }

        a {
            border-color: $bluish;
        }
    }
}

/*
    Medium screens
*/

@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    .img-title-content-card {
        a {
            transition: opacity 0.3s ease;

            &:hover,
            &:focus {
                outline: 0;
                opacity: 0.7;
            }
        }

        &--theme-orange,
        &--theme-red,
        &--theme-green,
        &--theme-purple,
        &--theme-blue {
            a {
                &:hover,
                &:focus {
                    .img-title-content-card__icon {
                        svg {
                            color: $white;
                        }
                    }
                }
            }
        }

        &--theme-orange {
            a {
                &:hover,
                &:focus {
                    .img-title-content-card__icon {
                        background-color: $sienna;
                    }
                }
            }
        }

        &--theme-green {
            a {
                &:hover,
                &:focus {
                    .img-title-content-card__icon {
                        background-color: $spruce;
                    }
                }
            }
        }

        &--theme-red {
            a {
                &:hover,
                &:focus {
                    .img-title-content-card__icon {
                        background-color: $red-wine;
                    }
                }
            }
        }

        &--theme-purple {
            a {
                &:hover,
                &:focus {
                    .img-title-content-card__icon {
                        background-color: $darkish-purple;
                    }
                }
            }
        }

        &--theme-blue {
            a {
                &:hover,
                &:focus {
                    .img-title-content-card__icon {
                        background-color: $bluish;
                    }
                }
            }
        }
    }
}

/*
    Large screens
*/

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .img-title-content-card {
        a {
            height: 100%;
            display: flex;
            flex-flow: row wrap;
            align-items: stretch;
            align-content: stretch;
            justify-content: center;
        }

        &__img {
            display: block;
            max-height: rem(200);
        }

        &__content {
            padding: rem(38) rem(32);
        }

        h4 {
            padding: 0 rem(40) 0 0;
            margin-bottom: rem(10);
            display: block;
        }

        p {
            display: inline-block;
        }

        &__icon {
            right: rem(32);
            width: rem(32);
            height: rem(32);
            top: rem(30);

            svg {
                width: rem(15);
                height: rem(15);
                transform: rotate(-90deg) translate(50%, -50%);
            }
        }

        &--theme-orange,
        &--theme-red,
        &--theme-green,
        &--theme-purple,
        &--theme-blue {
            height: 100%;

            a {
                height: 100%;
                margin: 0;
                display: flex;
                justify-content: center;
                flex-flow: row wrap;
                align-content: stretch;
                align-items: stretch;
            }
        }
    }
}

/*
    XLarge screens
*/

@media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    .img-title-content-card {
        &__content {
            padding: rem(41) rem(32);
        }

        &__icon {
            top: rem(40);
        }
    }
}

.nbr-space {
    height: 100%;
}

.th-label {
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 5px;
    color: #3a3a3a;
    max-height: 30px;
    overflow: hidden;
    font-size: small;
    margin-bottom: -25px;
    width: 100%;
}
