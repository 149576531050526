@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.card-wysiwyg {
    width: 100%;

    h3 {
        margin: 0;
        padding: 0;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        padding: 0 0 rem(40) 0;
    }


    &__inner {
        width: 100%;
        max-width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
    }


    &__lower {
        width: 100%;
        padding: rem(15) 0 0 0;
    }



}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .card-wysiwyg {
        &__inner {
            max-width: rem(465);
        }

    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .card-wysiwyg {

        &__inner {
            max-width: rem(745);
        }

        &__lower {
            padding: rem(20) 0 0 0;
        }

    }


}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .card-wysiwyg {

        &__lower {
            padding: rem(30) 0 0 0;
        }

        &__wrapper {
            padding: 0 0 rem(55) 0;
        }


    }


}