@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_comp-bg";
@import "./_rtl";

.media-download-block {

    width: 100%;

    a {
        width: 100%;
        height: rem(280);
        display: block;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        overflow: hidden;
        border-radius: rem(2);

        &[target="_blank"] {

            &:before {
                content: "";
                position: absolute;
                background-color: $black;
                opacity: 0.6;
                display: block;
                right: 0;
                bottom: 0;
                width: rem(25);
                height: rem(25);
                z-index: 1;
                opacity: 0;
                transition: opacity 0.4s ease;
            }

            &:after {
                content: url("../../assets/img/icon-new-tab-white.svg");
                position: absolute;
                right: rem(6);
                bottom: 0;
                z-index: 2;
                opacity: 0;
                transition: opacity 0.4s ease;
            }

        }
    }

    &__tag {
        width: rem(52);
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-flow: row nowrap;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $charcoal;
        padding: rem(14) rem(16);

        & > span {
            display: none;
            margin: 0 rem(15) 0 0;
        }

        svg {
            color: $white;
            width: rem(20);
            height: rem(20);
        }
    }
}

/*
    Medium screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

    .media-download-block {

        a {

            &[target="_blank"] {

                &:hover,
                &:focus {
                    &:before,
                    &:after {
                        opacity: 1;
                    }
                }

            }

            &:hover,
            &:focus {
                .media-download-block__tag {
                    transform: translateX(0);
                    
                    & > span {
                        opacity: 1;
                    }
                }
            }

        }


        &__tag {

            padding: rem(14) rem(20);
            transform: translateX(-70px);
            width: auto;
            transition: transform 0.5s ease;

            & > span {
                @include font-smoothing;
                display: inline-block;
                width: auto;
                font-size: rem(12);
                line-height: rem(12);
                color: $white;
                font-weight: 400;
                opacity: 0;
                transition: opacity 0.5s ease;
            }

        }

    }

}

/*
    XLarge screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

    .media-download-block {

        a {
            height: rem(365);
        }

    }

}