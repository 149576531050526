@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.feature-img {

    width: 100%;
    padding: 0 rem($padding);
    transition: opacity 0.4s ease, visibility 0.5s ease;
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.5s;

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

    &__bg {
        width: 100%;
        max-height: rem(158);
        padding: 56.25% 0 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        overflow: hidden;
        border-radius: rem(2);
    }

    &__caption {
        width: 100%;
        padding: rem(12) 0 0 0;
        font-size: rem(12);
        line-height: rem(20);
        color: $charcoal;
    }

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .feature-img {
        max-width: map-get($container-max-widths, 'sm');
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .feature-img {

        max-width: map-get($container-max-widths, 'md');

        &.animated {
            opacity: 0;
            transition: opacity 0.5s ease;
            transition-delay: 0.5s;
        }

        &.is-visible {
            opacity: 1;
        }


    }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .feature-img {
        max-width: rem(745);

        &__bg {
            height: rem(400);
            max-height: 100%;
            padding: 0;
        }

    }

}