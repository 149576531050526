body.#{(map-get($languages, 'arabic'))} {

    /*
        Medium Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        
        .column-carousel {

            &__right {
                &-inner {
                    right: 0;
                    left: inherit;
                }
            }

        }
        
    }

}