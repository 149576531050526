body.#{(map-get($languages, 'arabic'))} {

    .content-card {

        a {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: flex-start;
        }
    
        h3,
        h5,
        p {
            text-align: right;
        }

        h6 {
            padding: 0 0 0 rem(35);
        }

        &__icon {
            left: rem(32);
            right: inherit;
        }

        &--no-label {

            .content-card {

                &__icon {
                    left: rem(28);
                }

            }

        }

    }

    @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

        .content-card {

            h6 {
                padding: 0 0 0 rem(45);
            }

        }
    }


    @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

        .content-card {

            &--no-label {

                .content-card {

                    &__icon {
                        left: rem(30);
                    }

                }

            }

        }

    }

}

body.#{(map-get($languages, 'arabic'))}:not(.ie-11) {

    .content-card {

        &__icon {

            svg {
                transform: rotate(90deg);
            }
        }
    }

}