@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";

.feature-content {

    width: 100%;

    h6 {
        width: 100%;
        margin: 0;
        padding: 0 0 rem(12) 0;
        color: $charcoal;
    }

    h3 {
        margin: 0 0 rem(10) 0;
        padding: 0;
        color: $charcoal;
    }

    &__text {

        width: 100%;
        font-size: rem(14);
        line-height: rem(24);

        p {
            
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            margin: rem(22) 0;
            padding: 0;

        }

        ul,
        ol {
            
            margin: rem(22) 0;
            padding: 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
            
            li {
                margin: 0;
                padding: 0 0 rem(3) 0;
            }
        }

        ul {

            list-style: none;
            margin: 0 0 rem(22) 0;

            li {
                
                list-style: none;
                position: relative;
                padding: 0 0 0 rem(10);

                &:before {
                    content: "";
                    width: rem(4);
                    height: rem(1);
                    background-color: $charcoal;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: rem(12);
                }

            }

        }

    }

    &__cta {
        width: 100%;
        margin: rem(25) 0 0 0;

        a[target="_blank"] {
            &:after {
                content: url("../../assets/img/icon-new-tab-white.svg");
            }
        }

        .btn {
            margin: 0 rem(12) rem(12) 0;

            &:last-child {
                margin: 0;
            }
        }

    }

    &--landing {

        .feature-content {

            &__text {
                p,
                ol,
                ol li,
                ul,
                ul li { 
                    font-size: rem(14);
                    line-height: rem(24);
                }
            }

        }

    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .animated {
    
        .feature-content {
            transition: transform 1.1s ease, opacity 1s ease;
            opacity: 0;
            transform: translateY(20%);
            transition-delay: 0.4s;
        }
        
    }

    .is-visible {
        
        .feature-content {
            opacity: 1;
            transform: translateY(0);
        }

    }


    .feature-content {

        h3 {
            margin: 0 0 rem(15) 0;
        }

        &__text {
            font-size: rem(16);
            line-height: rem(26);
        }

        &__cta {

            margin: rem(40) 0 0 0;

            .btn {
                margin: 0 rem(20) 0 0;

                &:last-child {
                    margin: 0;
                }
            }
        }

    }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .feature-content {

        &--landing {

            .feature-content {

                &__text {
                    p,
                    ol,
                    ol li,
                    ul,
                    ul li { 
                        font-size: rem(21);
                        line-height: rem(32);
                    }
                }

            }

        }

    }

}