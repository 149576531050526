@each $bg, $item in $bg-styles {
    body.#{$bg}{

        $themeColour: $item;

        .arrow-btn {

            background-color: $white;
            border-color: $white;

            svg {
                color: $themeColour;
            }

        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {

            .arrow-btn {

                border-color: $white;

                &:focus,
                &:hover {

                    background-color: $themeColour;
                    
                    svg {
                        color: $white;
                    }

                }
            }
            
        }

    }
}