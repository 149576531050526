@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg.scss";
@import "./_theme.scss";
@import "./_rtl";

.colour-card {

    width: 100%;

    a {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.36);
        width: 100%;
        padding: rem(40) rem(32);
        text-decoration: none;
        text-align: center;

        &:hover,
        &:focus {
            text-decoration: none;
            outline: 0;
        }
    }

    &__icon {
        width: rem(26);
        height: rem(26);
        border-radius: rem(999);
        border: rem(3) solid $charcoal;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-flow: row wrap;
        margin: 0 0 rem(15) 0;
        overflow: hidden;

        svg {
            color: $charcoal;
            transform: rotate(-90deg);
            width: rem(12);
            height: rem(12);
        }
    }

    h3 {
        color: $charcoal;
        margin: 0 0 rem(10) 0;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    p {
        color: $charcoal;
        margin: 0;
        padding: 0;
        font-size: rem(14);
        line-height: rem(24);
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .colour-card {

        a {
            transition: box-shadow 0.5s ease, transform 0.5s ease;
            box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.36);
            opacity: 1;

            &:hover,
            &:focus {
                box-shadow: 0 rem(25) rem(31) 0 rgba($black, 0.26);
                transform: translateY(-5%);

                .colour-card__icon {
                    background-color: $charcoal;

                    svg {
                        color: $white;
                    }
                }
            }
        }

        &__icon {
            transition: background-color 0.5s ease;
        }

    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .colour-card {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        flex-flow: row wrap;
        height: 100%;

        &__icon {
            margin: 0 0 rem(20) 0;
            width: rem(32);
            height: rem(32);

            svg {
                width: rem(14);
                height: rem(14);
            }
        }

        a {
            padding: rem(60) rem(80);
            min-height: rem(355);
        }

        h3 {
            margin: 0 0 rem(15) 0;
        }
    
        p {
            font-size: rem(16);
            line-height: rem(26);
        }

    }

}