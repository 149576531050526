@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        .img-title-content-card__content {
            border-color: $white;
        }

        .img-title-content-card__icon {
            border-color: $item;

            svg {
                color: $item;
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .img-title-content-card {
                a {
                    &:hover,
                    &:focus {
                        .img-title-content-card__icon {
                            background-color: $item;

                            svg {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
