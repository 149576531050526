@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";
@import "./_lang";

.update-card {

    display: block;
    width: 100%;
    text-decoration: none;
    box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.07);
    overflow: hidden;
    border-radius: rem(2);
    background-color: $white;

    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
    }

    &__img {
        position: relative;
        width: 100%;
        height: rem(185);
    }

    &__lower {
        border-top: rem(4) solid $spruce;
        background-color: $white;
        padding: rem(32) rem(24);
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;
        overflow: hidden;
        position: relative;
    }

    h6 {
        color: $charcoal;
        width: 100%;
        margin: 0;
        padding: 0 0 rem(8) 0;
        font-size: rem(14);
        line-height: rem(24);
        font-family: $latin-body-font;
        font-weight: $latin-body-font-weight;
    }

    h4 {
        width: 100%;
        padding: 0;
        margin: 0;
        color: $spruce;
    }

    &__content {
        width: 100%;
        margin: rem(12) 0;
        color: $charcoal;
    }

    p {
        color: $charcoal;
        margin: 0;
        padding: 0;
    }

    &__tag {
        font-size: rem(14);
        line-height: rem(14);
        color: $charcoal;
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__icon {

        width: rem(26);
        height: rem(26);
        background-color: $spruce;
        border-radius: rem(999);
        overflow: hidden;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;
        transition: background-color 0.4s ease;
        border: rem(2) solid $spruce;

        svg {
            color: $white;
            width: rem(12);
            height: rem(12);
            min-width: rem(12);
            min-height: rem(12);
            transition: transform 0.4s ease;
            transform: rotate(-90deg);
        }
    }

    &__foot {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;

    }

    &--feature {

        background-color: $spruce;

        .update-card__content,
        h4,
        h6,
        p {
            @include font-smoothing;
            color: $white;
        }

        .update-card {

            &__lower {
                background-color: $spruce;
            }

            &__tag {
                color: $white;
            }

            &__icon {

                background-color: $white;
                border-color: $white;

                svg {
                    color: $spruce;
                }
            }

        }

    }

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .update-card {

        h4,
        &__content {
            max-width: 100%;
        }

        &__img {
            position: relative;
            width: 100%;
            height: rem(200);
        }
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .update-card {

        position: relative;
        transition: opacity 0.4s ease;

        h6 {
            margin: 0;
        }

        &:hover,
        &:focus {

            outline: 0;
            opacity: 0.8;

            .update-card__icon {

                background-color: $white;

                svg {
                    color: $spruce;
                }
            }
        }

        &--feature  {

            &:hover,
            &:focus {

                .update-card__icon {
                
                    background-color: $spruce;
                    border-color: $white;

                    svg {
                        color: $white;
                    }
                }
            }
        }

        &--large {

            min-height: rem(634);

            &:hover,
            &:focus {
                h6 {
                    color: $spruce;
                }
            }

            .update-card {
                &__img {
                    height: 100%;
                }

                &__lower {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                }
            }
        }

        &--feature.update-card--large {
            h6 {
                color: $white;
            }
        }

        &--no-img {

            h6 {
                transition-delay: 0s;
            }

            .update-card__lower {
                height: 100%;
                align-content: center;
                align-items: center;
            }

        }

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .update-card {

        min-height: rem(380);
        transition: none;

        &:hover,
        &:focus {

            opacity: 1;

            h6 {
                opacity: 1;
                margin: 0;
                visibility: visible;
            }

            .update-card__content {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                margin: rem(10) 0 rem(20) 0;
            }

        }

        &__img {
            height: 100%;
        }

        h4 {
            margin: 0;
            overflow: hidden;
            padding: 0 rem(37) 0 0;
        }

        h6 {
            padding: 0 rem(37) rem(15) 0;
            display: block;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.4s ease, visibility 0.5s ease, margin 0.6s ease;
            margin: 0 0 rem(-50) 0;
        }

        &__lower {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: rem(20) rem(20) rem(20) rem(20);
            min-height: rem(128);
            align-items: flex-start;
            align-content: flex-start;
        }

        &__content {
            margin: 0;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            transform: translateY(20%);
            transition: visibility 0.5s ease, opacity 0.6s ease, margin 0.6s ease, transform 0.6s ease;
            font-size: rem(14);
            line-height: rem(22);
            padding: 0 rem(37) 0 0;
        }

        &__foot {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: rem(20);
        }

        &--large {

            display: flex;
            align-content: stretch;
            align-items: stretch;
            flex-flow: row wrap;
            justify-content: center;

            .update-card {

                &__lower {
                    padding: rem(30) rem(30);
                }

                &__foot {
                    margin: rem(20) 0 0 0;
                }

                &__content {
                    margin: 0 0 rem(20) 0;
                }


                &__icon {
                    
                    width: rem(38);
                    height: rem(38);

                    svg {
                        width: rem(14);
                        height: rem(14);
                    }

                }
            }
        }


        &--no-img {

            &:hover,
            &:focus {
                
                .update-card__content {
                    transform: translateY(0);
                    margin: 0 0 rem(30) 0;
                }

            }

            h4 {
                margin: 0 0 rem(10) 0;
            }

            h6 {
                margin: 0;
                visibility: visible;
            }

            .update-card__lower {
                position: relative;
            }

            .update-card__content {
                transform: translateY(0);
                margin: 0 0 rem(30) 0;
                opacity: 1;
                visibility: visible;
            }

        }

    }

}