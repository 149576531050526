body.arabic-text {
    
    .map {
    
        &__locations button {
            font-family: $arabic-heading-font;
            font-weight: $arabic-heading-font-weight;
        }

    }
}

body.chinese-text {
    
    .map {
    
        &__locations button {
            font-family: $chinese-heading-font;
            font-weight: $chinese-heading-font-weight;
            font-style: normal;
        }

    }
}

body.russian-text {
    
    .map {
    
        &__locations button {
            font-family: $russian-heading-font;
            font-weight: $russian-heading-font-weight;
            font-style: normal;
        }

    }
}