@each $theme, $item in $template-themes {

    body.#{$theme}{

        $themeColour: $item;

        .arrow-btn {
            background-color: $themeColour;
            border-color: $themeColour;
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {

            .arrow-btn {


                &:focus,
                &:hover {
                    
                    background-color: $white;

                    svg {
                        color: $themeColour;
                    }
                }
            }
            
        }
    }

}