@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.feature-content-intro {

    width: 100%;

    &--landing {
        max-width: 80%;
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .feature-content-intro {

        &--landing {
            max-width: rem(555);
        }

    }

}