@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.gallery-carousel {

    width: 100%;

    .carousel__controls {
        margin: rem(40) 0 0 0;
    }

}