@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.carousel-wrapper {

    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;

    &__container {
        padding: 0 rem($padding);
        width: 100%;
    }

    &__slide-row {
        display: flex;
        flex-flow: row nowrap;
        transition: transform 0.6s ease;

        &.reduced-easing {
            transition: none;
        }
    }

    &__page {
        width: 100%;
        display: block;
        transition: opacity 0.6s ease;
        opacity: 0.3;
        pointer-events: none;
        padding: 0 rem(10);

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    &--no-padd {
        .carousel-wrapper__page {
            padding: 0;
        }
    }
}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .carousel-wrapper {

        &__container {
            max-width: rem(494);
            padding: 0;
        }

        &__page {
            display: flex;
            align-items: center;
            align-content: center;
            flex-flow: row wrap;
            justify-content: center;

            &:hover.active {
                opacity: 0.7;
            }
        }

        
    }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .carousel-wrapper {

        &__container {
            max-width: rem(594);
        }

    }
}