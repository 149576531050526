@each $bg, $item in $bg-styles {
    body.#{$bg}{

        .feature-content {

            &__text {
                color: $white;
            }

            p,
            ol,
            ol li,
            ul,
            ul li,
            h2,
            h6 {
                @include font-smoothing;
                color: $white;
            }

        }

    }
}