body.#{(map-get($languages, 'arabic'))} {

    .share {
        direction: rtl;

        button svg {
            margin: 0 0 0 rem(10);
        }

    }

}