body.#{(map-get($languages, 'arabic'))}:not(.ie-11) {

    .arrow-btn {

        &.prev {

            svg {
                transform: rotate(-90deg);
            }
        }

        &.next {

            svg {
                transform: rotate(90deg);
            }
        }

    }

}