// fonts
// ================================================

// english
$latin-body-font: 'Roboto', sans-serif;
$latin-body-font-weight: 400;

$latin-heading-font: 'Futura', sans-serif;
$latin-heading-font-weight: 700;

// arabic
$arabic-body-font: 'Noto Kufi Arabic', sans-serif;
$arabic-body-font-weight: 400;

$arabic-heading-font: 'Janna LT', sans-serif;
$arabic-heading-font-weight: 700;


// Russian
$russian-body-font: $latin-body-font;
$russian-body-font-weight: $latin-body-font-weight;

$russian-heading-font: 'Futura PT', sans-serif;
$russian-heading-font-weight: 700;


// chinese
$chinese-body-font: 'Microsoft YaHei', sans-serif;
$chinese-body-font-weight: 700;

$chinese-heading-font: "SF Pro HK", sans-serif;
$chinese-heading-font-weight: 400;


$body-size: 16px;
$body-line: 24px;

// colours
// ================================================
$black:            #000000;
$white:            #ffffff;

$black-two:                     #111111;
$bluish:                        #297bb3;
$brown-grey:                    #949494;
$brownish-grey:                 #707070;
$brownish-grey-two:             #757575;
$brownish-orange:               #db6423;
$charcoal:                      #3a3a3a;
$darkish-purple:                #611773;
$dark-sky-blue:                 #489dd6;
$golden:                        #f1b900;
$red-wine:                      #900036;
$sienna:                        #c14a1d;
$spruce:                        #0c652a;
$very-light-pink:               #e7e7e7;
$white-two:                     #f9f9f9;


// themes
// ================================================
$template-themes: (
        "theme-green":          $spruce,
        "theme-blue":           $bluish,
        "theme-orange":         $sienna,
        "theme-red":            $red-wine,
        "theme-purple":         $darkish-purple,
);

// bg styles
// ================================================
$bg-styles: (
        "bg-green":             $spruce,
        "bg-blue":              $bluish,
        "bg-orange":            $sienna,
        "bg-red":               $red-wine,
        "bg-purple":            $darkish-purple
);

// component bg styles
// ================================================
$component-bg-styles: (
        "bg-green":             $spruce,
        "bg-blue":              $bluish,
        "bg-orange":            $sienna,
        "bg-red":               $red-wine,
        "bg-purple":            $darkish-purple,
        "bg-grey":              $white-two,
);

$component-bg-styles-dark: (
        "bg-green":             $spruce,
        "bg-blue":              $bluish,
        "bg-orange":            $sienna,
        "bg-red":               $red-wine,
        "bg-purple":            $darkish-purple
);


// language classes
// ================================================

$languages: (
        'arabic': arabic-text,
        'russian': russian-text,
        'chinese': chinese-text
);

// Grid Options - Bootstrap 4 grid
// ================================================
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1280px,
) !default;

// Grid containers
// ================================================
$container-max-widths: (
        sm: 505px,
        md: 675px,
        lg: 800px,
        xl: 1024px,
) !default;

// global padding value
// ================================================
$padding: 20;

// print spacing
// ================================================
$print-spacing: 10;

// component spacing
// ================================================
$component-spacing-xs:          40;
$component-spacing-md:          60;
$component-spacing-lg:          70;
$component-spacing-xl:          80;