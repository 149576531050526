@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .side-bar {
            &__action {
                button {
                    background-color: $itemColour;
                    border-color: $itemColour;
                    color: $white;

                    &:before {
                        background-color: $itemColour;
                    }
                }
            }
        }

        /*
            Medium Screens
        */

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .side-bar {
                &__action {
                    button {
                        &:hover,
                        &:focus {
                            background-color: $white;
                            border-color: $itemColour;
                            color: $itemColour;
                        }
                    }
                }
            }
        }
    }
}
