@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

$limit: 300;
$delay: 0.4;

.colour-card-row {

    width: 100%;
    @include component-spacing;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;

    &__item {
        width: 100%;
        margin: 0 0 rem(12) 0;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
    }

    &__inner {
        padding: 0 rem($padding);
    }

    @include centre-align-container;

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .colour-card-row {

        &__inner {
            max-width: map-get($container-max-widths, 'sm');
        }

    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .colour-card-row {

        &__inner {
            max-width: rem(500);
        }

    }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .animated {

        .colour-card-row {

            &__item {

                transition: opacity 0.6s ease, transform 0.6s ease;
                opacity: 0;
                transform: translateY(10%);
                transition-delay: 0.4s;
                display: flex;
                justify-content: stretch;
                flex-flow: row wrap;
                align-content: stretch;
                align-items: stretch;

                @for $i from 1 through $limit {
                    &:nth-child(#{$i}) {
                        transition-delay: ($delay * $i) + s;
                    }
                }

            }

        }

    }

    .is-visible {

        .colour-card-row {

            &__item {
                opacity: 1;
                transform: translateY(0);
            }

        }

    }

    .colour-card-row {

        &__inner {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            align-items: stretch;
            align-content: stretch;
            max-width: map-get($container-max-widths, 'lg');
        }

        &__item {
            width: 48.8%;
            margin: 0 0 rem(30) 0;
        }

    }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    
    .colour-card-row {
        &__inner {
            max-width: map-get($container-max-widths, 'xl');
        }
    }

}
