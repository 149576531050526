body.#{(map-get($languages, 'arabic'))} {

    .download-link {
        direction: rtl;

        svg {
            margin: 0 0 0 rem(5);
        }

    }

}