@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

a.link-block,
.link-block {

    display: block;
    width: 100%;
    border-radius: rem(2);
    background-color: $white;
    box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.07);
    padding: rem(36) rem(32);
    margin: 0 0 rem(12) 0;
    text-decoration: none;

    &:last-child {
        margin: 0;
    }

    &--no-margin {
        margin: 0;
    }

    &[target="_blank"] {

        &:after {
            display: none;
        }

        span {
            
            &:after {
                content: url("../../assets/img/icon-new-tab.svg");
                padding: 0 0 0 rem(5);
            }
        }

    }
    
    p {
        padding: 0 0 rem(30) 0;
        width: 100%;
        display: block;
        text-decoration: none;
        color: $charcoal;
    }

    span {
        font-size: rem(14);
        line-height: rem(24);
        text-decoration: underline;
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    a.link-block,
    .link-block {

        &:hover,
        &:focus {
            outline: 0;

            span {
                text-decoration: none;
            }
        }

    }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    a.link-block,
    .link-block {

        margin: 0 0 rem(20) 0;

        p {
            padding: 0 0 rem(24) 0;
        }

    }
    

}