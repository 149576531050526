@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.icon-block {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;

    &__icon {
        width: rem(90);
        height: rem(90);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
    
    &__content {

        width: 100%;
        text-align: center;
        margin: rem(10) 0 0 0;

        p {
            font-size: rem(14);
            line-height: rem(24);
            color: $charcoal;
            margin: 0;
            padding: 0;
        }

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .icon-block {

        &__icon {
            width: rem(130);
            height: rem(130);
        }

        &__content {

            margin: rem(25) 0 0 0;

            p {
                font-size: rem(16);
                line-height: rem(26);
            }

        }

    }

    
}