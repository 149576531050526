@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .feature-logo-row {
            &__logo svg,
            h3 {
                color: $itemColour;
            }
        }
    }
}
