@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";

.grey-content-row {

    width: 100%;
    @include component-spacing;

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;
        background-color: $white-two;
        padding: rem(60) 0;
    }

    &__inner {
        width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
    }

    h2 {

        padding: 0;

        &:first-child:not(:only-child) {
            margin: 0 0 rem(15) 0;
        }
    }

    p {
        padding: 0;
        margin: 0;
    }

    &__left,
    &__right {
        width: 100%;
    }

    &__left {
        margin: 0 0 rem(40) 0;
    }

    &__item {
        margin: 0 0 rem(12) 0;

        &:last-child {
            margin: 0;
        }
    }

    @include centre-align-container;

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .grey-content-row {

        &__wrapper {
            padding: rem(80) 0;
        }

        &__inner {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;
        }

        &__left {
            width: 45%;
        }

        &__right {
            width: 45%;
        }

        &--columns {

            .grey-content-row__left,
            .grey-content-row__right {
                width: 100%;
            }

        }

    }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .grey-content-row {

        &__wrapper {
            padding: rem(80) 0;
        }

        &__inner {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;
        }

        &__left {
            width: 45%;
        }

        &__right {
            width: 45%;
        }

        &--columns {

            .grey-content-row__item {
                width: 41%;
                margin: 0 rem(10);
            }

            .grey-content-row__left {
                
                display: flex;
                justify-content: center;
                flex-flow: row wrap;
                align-content: flex-start;
                align-items: flex-start;
                text-align: center;

                p {
                    max-width: 80%;
                }
            }

            .grey-content-row__right {
                display: flex;
                justify-content: center;
                flex-flow: row wrap;
                align-items: stretch;
                align-content: stretch;
            }

        }

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .grey-content-row {
        
        &__wrapper {
            padding: rem(160) 0;
        }

        &__left {
            width: 50%;
            max-width: rem(460);
        }

        &__right {
            width: 50%;
            max-width: rem(365);
        }

        &__item {
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            align-items: stretch;
            align-content: stretch;
        }

        &--columns {

            .grey-content-row__item {
                width: 48.5%;
                margin: 0;
            }

            .grey-content-row__left {
                justify-content: flex-start;
                text-align: left;

                p {
                    max-width: 100%;
                }
            }

            .grey-content-row__right {
                width: 50%;
                max-width: 50%;
                justify-content: space-between;
            }

        }

    }

}