@each $theme, $item in $template-themes {
    body.#{$theme} {
        $themeColour: $item;

        .header {
            &__left {
                svg,
                a span svg {
                    color: $themeColour;
                }
            }

            &.active {
                .header__mobile-btn {
                    span {
                        background-color: $white;
                    }
                }
            }

            &__action-btn {
                background-color: $white;
                border-color: $white;
                color: $themeColour;
            }

            &__language ul li button {
                &.active,
                &:hover,
                &:focus {
                    color: $themeColour;
                }
            }

            &__lower {
                background-color: $themeColour;
            }

            .social ul li a {
                &:focus {
                    border-color: $white;
                }

                svg {
                    color: $white;
                }
            }
        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .header {
                &.active {
                    .header__mobile-btn {
                        &:focus {
                            border-color: $white;
                        }
                    }
                }

                &__action-btn {
                    &:hover,
                    &:focus {
                        background-color: $themeColour;
                        color: $white;
                    }
                }

                &__left {
                    a {
                        &:focus {
                            &:before {
                                border-color: $themeColour;
                            }
                        }
                    }
                }

                &__mobile-btn {
                    &:focus {
                        border-color: $themeColour;
                    }
                }
            }
        }

        /*
            XLarge Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "xl")) {
            .header {
                &__lower {
                    background-color: transparent;
                }

                &__language ul li button {
                    &.active:before,
                    &:hover:before,
                    &:before {
                        background-color: $themeColour;
                    }
                }
            }
        }
    }
}
