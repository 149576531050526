@each $theme, $item in $template-themes {
    body.#{$theme} {
        $themeColour: $item;

        h1 {
            color: $themeColour;
        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .home-banner {
                &__scroll {
                    &:hover {
                        &:before {
                            border-left-color: $themeColour;
                            border-bottom-color: $themeColour;
                        }
                    }

                    &:focus {
                        border-color: $themeColour;
                    }
                }
            }
        }
    }
}
