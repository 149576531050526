body.arabic-text {
    
    .footer {
    
        &__links ul li {
            font-family: $arabic-heading-font;
            font-weight: $arabic-heading-font-weight;
        }
    }
}

body.chinese-text {
    
    .footer {
    
        &__links ul li {
            font-family: $chinese-heading-font;
            font-weight: $chinese-heading-font-weight;
            font-style: normal;
        }

    }
}

body.russian-text {
    
    .footer {
    
        &__links ul li {
            font-family: $russian-heading-font;
            font-weight: $russian-heading-font-weight;
            font-style: normal;
        }
        
    }
}