body.#{(map-get($languages, "arabic"))} {
    .download-card {
        &__details {
            .download-card-button {
                margin: 0 0 0 rem(20);
            }
            svg {
                margin: 0 0 0 rem(5);
            }
        }
    }
}
