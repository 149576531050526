body.#{(map-get($languages, 'arabic'))} {

    .img-title-content-card {

        &__content {
            text-align: right;
        }
        
        &__icon {
            right: inherit;
            left: rem(24);

            svg {
                right: 50%;
                left: inherit;
                top: rem(-3);
                transform: rotate(-90deg) translate(-50%, -50%);
            }

        }

        h4 {
            padding: 0 0 0 rem(36);
        }

    }

    @media screen and (min-width:  map-get($grid-breakpoints, 'lg')) {

        .img-title-content-card {
            
            &__icon {
                left: rem(32);

                svg {
                    top: rem(-2);
                }
            }

            h4 {
                padding: 0 0 rem(12) rem(40);
            }

        }

    }

}

body.#{(map-get($languages, 'arabic'))}:not(.ie-11) {

    .img-title-content-card {
        
        &__icon {

            svg {
                top: 50%;
                transform: rotate(90deg) translate(-50%, -50%);
            }

        }
    }

    @media screen and (min-width:  map-get($grid-breakpoints, 'lg')) {
        .img-title-content-card {
        
            &__icon {
    
                svg {
                    top: 50%;
                }
    
            }
        }
    }
}