body.#{(map-get($languages, 'arabic'))} {

    .feature-content {

        &__text {

            ul {

                li {

                    padding: 0 rem(10) 0 0;

                    &:before {
                        right: 0;
                        left: inherit;
                    }
                }

            }

        }

        &__cta {

            .btn {
                margin: 0 0 rem(12) rem(12);
            }

        }


    }

    /*
        Large Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

        .feature-content {

            &__cta {

                .btn {
                    margin: 0 0 0 rem(20);
                }

            }


        }


    }

}