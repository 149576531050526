@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";

.instagram-item {

    width: 100%;
    border-radius: rem(2);
    overflow: hidden;

    &__inner {
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: rem(280);
        text-decoration: none;
        position: relative;

        video {
            position: absolute;
            height: 100%;
            left: 0;
            width: 100%;
            top: 0;
        }

        &:focus {
            outline: 0;
            border: rem(3) solid $white;
            box-shadow: inset 0 0 0 rem(4) $charcoal;
        }

    }

    video {
        object-fit: cover;
    }
    
}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .instagram-item {

        &__inner {
            height: rem(350);
        }
        
    }

}


/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .instagram-item {

        &__inner {
            height: rem(363);
        }

    }

}