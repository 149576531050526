@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_rtl";

.download-link {
    
    display: inline-block;
    font-size: rem(12);
    line-height: rem(20);
    border: rem(2) solid $charcoal;
    border-radius: rem(2);
    padding: rem(9) rem(18) rem(9) rem(12);
    color: $charcoal;
    text-decoration: none;
    height: 100%;
    transition: background-color 0.4s ease;

    &:hover,
    &:focus {
        outline: 0;
        text-decoration: none;
        background-color: $charcoal;
        color: $white;

        svg {
            color: $white;
        }
    }

    svg {
        width: rem(20);
        height: rem(20);
        display: inline-block;
        vertical-align: middle;
        margin: 0 rem(5) 0 0;
        color: $charcoal;
    }
}