@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_rtl";

.download-card {
    width: 100%;
    background-color: $white;
    border-radius: rem(2);
    box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.07);
    display: block;
    padding: rem(32);
    text-decoration: none;

    h6 {
        font-size: rem(14);
        line-height: rem(24);
        color: $charcoal;
        font-weight: 400;
        margin: 0 0 rem(12) 0;
        padding: 0;
    }

    p {
        font-size: rem(14);
        line-height: rem(24);
        margin: 0;
        padding: 0;
        color: $charcoal;
    }

    &__details {
        margin: rem(24) 0 0 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-flow: row wrap;
        color: $charcoal;
        font-size: rem(12);
        line-height: rem(12);

        svg {
            width: rem(20);
            height: rem(20);
            color: $charcoal;
            margin: 0 rem(5) 0 0;
            display: inline-block;
            vertical-align: middle;
        }

        .download-card-button {
            margin: 0 rem(20) 0 0;

            border: rem(2) solid $charcoal;
            border-radius: rem(2);
            padding: rem(9) rem(18) rem(9) rem(12);
            transition: background-color 0.4s ease;
        }
    }
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    .download-card {
        &:hover,
        &:focus {
            outline: 0;

            h6 {
                text-decoration: underline;
            }

            .download-card-button {
                background-color: $charcoal;
                color: $white;

                svg {
                    color: $white;
                }
            }
        }
    }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .download-card {
        p {
            font-size: rem(16);
            line-height: rem(26);
        }
    }
}
