@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";
@import "./_theme";
@import "./_lang";

.content-card {

    width: 100%;

    &__icon {
        position: absolute;
        right: rem(32);
        top: rem(25);
        width: rem(26);
        height: rem(26);
        border-radius: rem(999);
        border: rem(3) solid $charcoal;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;

        svg {
            transform: rotate(-90deg);
            width: rem(12);
            height: rem(8);
            color: $charcoal;
        }
    }

    a {
        text-decoration: none;
        background-color: $white;
        display: block;
        padding: rem(32);
        box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.07);
        position: relative;
        overflow: hidden;
        border-radius: rem(2);

        &:hover,
        &:focus {
            outline: 0;
            text-decoration: none;
        }
    }

    h4 {
        margin: 0 0 rem(8) 0;
        padding: 0;
        color: $charcoal;
        width: 100%;
    }

    p {
        padding: 0;
        margin: 0;
        font-weight: 400;
        color: $charcoal;
        font-size: rem(14);
        line-height: rem(24);
        width: 100%;
    }

    h6 {
        font-family: $latin-body-font;
        font-weight: $latin-body-font-weight;
        margin: 0 0 rem(12) 0;
        padding: 0 rem(35) 0 0;
        color: $charcoal;
        width: 100%;
    }

    &--no-label {

        a {
            padding: rem(24) rem(24) rem(65) rem(24);
            border-bottom: rem(4) solid $charcoal;
        }

        .content-card {

            &__icon {
                bottom: rem(28);
                top: inherit;
                right: rem(28);
            }

        }

    }
}


/*
    Medium Screens
*/
@media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

    .content-card {

        a {
            height: 100%;

            &:hover,
            &:focus {

                .content-card__icon {
                    
                    background-color: $charcoal;
                    
                    svg {
                        color: $white;
                    }
                }
            }
        }


        &__icon {
            transition: background-color 0.5s ease;
        }


    }

}


/*
    XLarge Screens
*/
@media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

    .content-card {

        &__icon {
            width: rem(32);
            height: rem(32);
            
            svg {
                width: rem(12);
                height: rem(12);
            }
        }

        h4 {
            margin: 0 0 rem(12) 0;
            width: 100%;
            max-width: rem(257);
        }

        p {
            font-size: rem(16);
            line-height: rem(26);
        }

        h6 {
            padding: 0 rem(45) 0 0;
        }

        &--no-label {

            h4 {
                max-width: 100%;
            }

            a {
                padding: rem(40) rem(32) rem(80) rem(32);
            }

            .content-card {
                &__icon {
                    bottom: rem(36);
                    right: rem(30);
                }
            }

        }

    }

}