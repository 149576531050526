@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .map {
            &__info,
            &__continents {
                svg {
                    color: $itemColour;
                }
            }

            &__locations {
                button {
                    background-color: $itemColour;

                    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
                        &:focus,
                        &:hover {
                            color: $itemColour;
                            background-color: $white;

                            svg {
                                color: $itemColour;
                            }
                        }
                    }
                }
            }
        }
    }
}
