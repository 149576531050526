body.#{(map-get($languages, 'arabic'))} {

    .media-download-block {

        a {

            &[target="_blank"] {

                &:before {
                    right: inherit;
                    left: 0;
                }

                &:after {
                    left: 0;
                    right: inherit;
                }

            }
        }

        &__tag {
            right: 0;
            left: inherit;
            transform: translateX(65px);

            & > span {
                margin: 0 0 0 rem(15);
            }
        }

    }


}