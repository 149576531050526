@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_rtl";
@import "./_lang";

.map {

    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    @include component-spacing;

    &__inner {
        width: 100%;
        padding: 0 rem($padding);
    }

    &__continents {

        svg {
            opacity: 0.2;
            display: none;
            width: 100%;
            height: 100%;
        }

    }

    &__locations {
        
        list-style: none;
        padding: 0;
        margin: 0;

        li:not([class]) {
            list-style: none;
            padding: 0;
            margin: 0 0 rem(15) 0;

            &:before,
            &:first-child:before,
            &:not([class]):before {
                display: none;
            }

        }

        button {
            @include font-smoothing;
            @include clear-default-appearance;
            width: 100%;
            font-size: rem(14);
            line-height: rem(14);
            text-align: center;
            font-family: $latin-heading-font;
            font-weight: 700;
            cursor: pointer;
            padding: rem(22) rem(25);
            color: $white;
            background-color: $black;
            border-radius: rem(6);
            position: relative;

            svg {
                display: none;
            }

            &:focus {
                outline: 0;
            }
        }


    }

    &__info {
        display: none;
    }

}

/*
    Small Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .map {

        &__inner {
            max-width: map-get($container-max-widths, 'sm');
        }

    }

}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .map {

        &__inner {
            max-width: map-get($container-max-widths, 'md');
        }

        &__locations {

            button {
                transition: background-color 0.4s ease;
                box-shadow: 0 1px 7px -3px $brownish-grey-two;
            }
        }

    }

}

/*
    Large Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .map {

        &__inner {
            max-width: map-get($container-max-widths, 'lg');
        }

        &__wrapper {

            position: relative;
            padding: 0 0 rem(40) 0;

            svg {
                display: block;
            }

        }

        &__continents {
            svg {
                height: rem(417);
            }
        }

        &__locations {

            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            li {
                margin: 0;
                padding: 0;
            }

            button {
                width: auto;
                font-size: rem(14);
                line-height: rem(14);
                padding: rem(8) rem(15) rem(8) rem(30);
                position: absolute;

                &:focus,
                &:hover {
                    background-color: $black;
                    color: $white;
                    
                    svg {
                        color: $white;
                    }
                }

                &[data-continent="africa"] {
                    left: rem(390);
                    top: rem(270);
                }

                &[data-continent="americas"] {
                    left: rem(140);
                    top: rem(190);
                }

                &[data-continent="north-africa-middle-east"] {
                    left: rem(315);
                    top: rem(190);
                }

                &[data-continent="asia-pacific"] {
                    right: rem(60);
                    top: rem(240);
                }

                &[data-continent="europe-and-central-asia"] {
                    right: rem(100);
                    top: rem(120);
                }

                &:hover,
                &:focus {
                    
                    outline: 0;
                    text-decoration: none;

                    svg {
                        transform: rotate(180deg);
                    }
                }

                svg {
                    width: rem(12);
                    height: rem(12);
                    color: $white;
                    position: absolute;
                    left: rem(10);
                    top: rem(8);
                    transition: transform 0.4s ease;
                    display: block;
                }
            }

        }

        &__info {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            align-content: center;
            width: auto;
            background-color: $white-two;
            border-radius: rem(2);
            color: $charcoal;
            font-size: rem(12);
            line-height: rem(20);
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            padding: rem(15) rem(23);

            svg {
                width: rem(20);
                height: rem(20);
                margin: 0 rem(11) 0 0;
            }
        }

    } 

}


/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .map {

        &__inner {
            max-width: map-get($container-max-widths, 'xl');
        }

        &__wrapper {

            padding: 0 0 rem(30) 0;

        }

        &__continents {
            svg {
                height: rem(598);
            }
        }

        &__locations {

            button {
                
                font-size: rem(14);
                line-height: rem(14);
                padding: rem(11) rem(15) rem(11) rem(35);

                &[data-continent="africa"] {
                    left: rem(569);
                    top: rem(382);
                }

                &[data-continent="americas"] {
                    left: rem(198);
                    top: rem(300);
                }

                &[data-continent="north-africa-middle-east"] {
                    left: rem(460);
                    top: rem(270);
                }

                &[data-continent="asia-pacific"] {
                    right: rem(120);
                    top: rem(330);
                }

                &[data-continent="europe-and-central-asia"] {
                    right: rem(233);
                    top: rem(179);
                }

                svg {
                    width: rem(15);
                    height: rem(15);
                    left: rem(10);
                    top: rem(9);
                }
            }

        }

    }

}