body.#{(map-get($languages, 'arabic'))} {

    .home-banner {

        &__item {
            text-align: right;
        }

        &__scroll {
            left: inherit;
            right: rem(40);
        }

        &__pause-play {
            direction: ltr;
            right: inherit;
        }

    }

    // Medium Screens
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {

        .home-banner {        
            &__scroll {
                right: rem(97);
            }

            &__pause-play {
                left: rem(77);
            }
        }
        

    }

    // XLarge Screens
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

        .home-banner {

            &__scroll {
                right: 12.5vw;
            }

            &__pause-play {
                left: rem(40);
            }
    
        }
    }
    
}