@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";

.feature-logo-row {

    @include component-spacing;
    width: 100%;

    &__wrapper {
        background-color: $white-two;
        padding: rem(60) 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
    }

    &__inner {
        width: 100%;
        max-width: rem(935);
        padding-left: rem($padding);
        padding-right: rem($padding);
        text-align: center;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
    }

    h3 {
        margin: 0;
        padding: 0 0 rem(12) 0;
    }

    p {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }

    &__logo {

        width: rem(140);
        margin: rem(41) 0 0 0;

        svg {
            color: $charcoal;
            width: rem(140);
            height: rem(50);
        }

    }

}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .feature-logo-row {

        &__wrapper {
            padding: rem(80) 0;
        }

    }


}


/*
    Large Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .feature-logo-row {

        h3 {
            padding: 0 0 rem(20) 0;
        }

        &__logo {
            
            width: rem(190);

            svg {
                color: $charcoal;
                width: rem(190);
                height: rem(65);
            }
        }

    }


}

/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .feature-logo-row {

        &__wrapper {
            padding: rem(160) 0;
        }

    }


}