body.#{(map-get($languages, 'arabic'))} {
    
    .footer {

        &__links {
            ul {
                li {
                    font-family: $arabic-heading-font;
                    font-weight: $arabic-heading-font-weight;
                }
            }
        }

        &__unfe {
            margin: 0 0 0 rem(26);
        }

        .social ul {
            justify-content: center;
        }

    }

    /* 
        Large screens
    */
    @media screen and (min-width:  map-get($grid-breakpoints, 'lg')) {
        
        .footer {

            &__links {
                ul {
                    li {
                        margin: 0 0 0 rem(25);

                        a {
                            &:after {
                                left: inherit;
                                right: rem(-2);
                            }
                        }

                    }
                }
            }

            &__copyright {
                text-align: right;
            }

            .social ul {
                justify-content: flex-start;
            }
        }

    }


    /* 
        XLarge screens
    */
    @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {
        
        .footer {

            &__links {
                ul {
                    li {
                        margin: 0 0 0 rem(30);
                    }
                }
            }

        }

    }

}