@each $bg, $item in $bg-styles {
    body.#{$bg} {
        $itemColour: $item;

        .side-bar {
            &__action {
                button {
                    color: $itemColour;
                    border-color: $white;

                    &:before {
                        background-color: $white;
                    }
                }
            }
        }

        /*
            Medium Screens
        */

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .side-bar {
                &__action {
                    button {
                        &:hover,
                        &:focus {
                            background-color: $itemColour;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
