@function rem($size) {
	$remSize: calc($size / 16);
	@return #{$remSize}rem;
}

@mixin shadow {
	box-shadow: 0 rem(2) rem(11) 0 rgba($black, 0.07);
}

@mixin sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important; 
}

@mixin sr-only-focusable {
	clip: auto !important;
	-webkit-clip-path: none !important;
	clip-path: none !important;
	height: auto !important;
	margin: auto !important;
	overflow: visible !important;
	width: auto !important;
	white-space: normal !important;
}

@mixin clear-default-appearance {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	padding: 0;
	background: none;
}

@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin component-spacing {

	padding-top: rem($component-spacing-xs);
	padding-bottom: rem($component-spacing-xs);

	&--no-spacing {
		padding-top: 0;
		padding-bottom: 0;
	}

	&--spacing-top {
		padding-top: rem($component-spacing-xs);
		padding-bottom: 0;
	}

	&--spacing-bottom {
		padding-top: 0;
		padding-bottom: rem($component-spacing-xs);
	}

	/*
	    Medium Screens
	*/
	@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
		
		padding-top: rem($component-spacing-md);
		padding-bottom: rem($component-spacing-md);

		&--no-spacing {
			padding-top: 0;
			padding-bottom: 0;
		}
	
		&--spacing-top {
			padding-top: rem($component-spacing-md);
			padding-bottom: 0;
		}
	
		&--spacing-bottom {
			padding-top: 0;
			padding-bottom: rem($component-spacing-md);
		}

	}


	/*
	    Large Screens
	*/
	@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

		padding-top: rem($component-spacing-lg);
		padding-bottom: rem($component-spacing-lg);

		&--no-spacing {
			padding-top: 0;
			padding-bottom: 0;
		}
	
		&--spacing-top {
			padding-top: rem($component-spacing-lg);
			padding-bottom: 0;
		}
	
		&--spacing-bottom {
			padding-top: 0;
			padding-bottom: rem($component-spacing-lg);
		}

	}


	/*
	    XLarge Screens
	*/
	@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

		padding-top: rem($component-spacing-xl);
		padding-bottom: rem($component-spacing-xl);

		&--no-spacing {
			padding-top: 0;
			padding-bottom: 0;
		}
	
		&--spacing-top {
			padding-top: rem($component-spacing-xl);
			padding-bottom: 0;
		}
	
		&--spacing-bottom {
			padding-top: 0;
			padding-bottom: rem($component-spacing-xl);
		}

	}

}


@mixin centre-align-container {

	&__inner {
		width: 100%;
	}

	/*
	    Small Screens
	*/
	@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

		&__inner {
			max-width: map-get($container-max-widths, 'sm');
		}

	}

	/*
	    Medium Screens
	*/
	@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
		&__inner {
			max-width: map-get($container-max-widths, 'md');
		}
	}


	/*
	    Large Screens
	*/
	@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
		&__inner {
			max-width: map-get($container-max-widths, 'lg');
		}
	}

	/*
	    XLarge Screens
	*/
	@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
		&__inner {
			max-width: map-get($container-max-widths, 'xl');
		}
	}

}