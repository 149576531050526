body.#{(map-get($languages, 'arabic'))} {


    /*
        Medium Screens
    */

    @media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

        .header {
            &.top {
                .header__left {

                    a span svg {
                        height: rem(30);
                    }

                }
            }
        }

    }


    /*
        XLarge Screens
    */

    @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

        .header {

            &.top {

                .header__left {

                    a span svg {
                        height: rem(35);
                    }

                }

            }
            
            &__left {
                
                margin: 0;

                a {
                    margin: 0 0 0 rem(30);
                }
            }

            &.scroll {
                .header__lower {
                    transition: opacity 0.5s ease, visibility 0.6s ease, top 0.5s ease;
                }
            }
            
            &__lower {
                background-color: transparent;
                justify-content: flex-end;
                transition: opacity 0.5s ease, visibility 0.6s ease;
            }

        }

    }

}