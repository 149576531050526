@each $bg, $item in $bg-styles {
    body.#{$bg} {
        .section-intro {
            h2,
            &__content {
                color: $white;
            }
        }
    }
}

@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        .section-intro {
            h2,
            &__content {
                color: $white;
            }
        }
    }
}
