@each $theme, $item in $template-themes {
    body.#{$theme} {
        $bgColour: $item;
        $textColour: $white;

        .footer {
            &__wrapper {
                background-color: $bgColour;
            }

            &__links {
                ul li a,
                ul li {
                    @include font-smoothing;
                    color: $textColour;
                }

                ul li a:after {
                    background-color: $textColour;
                }
            }

            .social li svg,
            &__copyright,
            &__unfe svg,
            &__stand-up svg {
                @include font-smoothing;
                color: $textColour;
            }

            &__logos,
            .social li a:focus {
                border-color: $textColour;
            }
        }

        /* 
            Medium screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .footer {
                &__stand-up,
                &__unfe {
                    a {
                        &:focus {
                            border: rem(4) solid $bgColour;
                            box-shadow: 0 0 0 rem(2) $textColour;
                        }
                    }
                }
            }
        }
    }
}

/*
    Footer theme shape placement
*/

body.theme-blue {
    .footer {
        &__shape {
            left: rem(-270);
            top: rem(-470);
            width: rem(1400);
            height: rem(1400);
            opacity: 0.7;
            transform: rotate(-15deg);
        }
    }

    /* 
        Large screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
        .footer {
            &__shape {
                left: rem(-70);
                top: rem(-1130);
                width: rem(2000);
                height: rem(2000);
                opacity: 0.9;
                transform: rotate(-10deg);
            }
        }
    }
}

body.theme-orange {
    .footer {
        &__shape {
            right: rem(-370);
            top: rem(-420);
            width: rem(1300);
            height: rem(1300);
            opacity: 1;
            transform: rotate(157deg);
        }
    }

    /* 
        Large screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
        .footer {
            &__shape {
                right: -200px;
                top: -960px;
                width: 1990px;
                height: 1990px;
                transform: rotate(149deg);
            }
        }
    }
}

body.theme-purple {
    .footer {
        &__shape {
            left: rem(-700);
            top: rem(-470);
            width: rem(1300);
            height: rem(1300);
            transform: rotate(-90deg);
            opacity: 1;
        }
    }

    /* 
        Large screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
        .footer {
            &__shape {
                left: rem(-490);
                top: rem(-1180);
                width: rem(1860);
                height: rem(1960);
                transform: rotate(-79deg);
            }
        }
    }
}

body.theme-green {
    .footer {
        &__shape {
            right: rem(-280);
            top: rem(-250);
            width: rem(1700);
            height: rem(1700);
            transform: rotate(-180deg);
            opacity: 1;
        }
    }

    /* 
        Large screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
        .footer {
            &__shape {
                right: rem(-140);
                top: rem(-530);
                width: rem(2140);
                height: rem(2140);
                transform: rotate(-175deg);
            }
        }
    }
}

body.theme-red {
    .footer {
        &__shape {
            right: rem(-450);
            top: rem(-250);
            width: rem(2000);
            height: rem(2000);
            transform: rotate(-207deg);
            opacity: 0.7;
        }
    }

    /* 
        Large screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
        .footer {
            &__shape {
                right: rem(-160);
                top: rem(-706);
                width: rem(2500);
                height: rem(2500);
                transform: rotate(-220deg);
            }
        }
    }

    /* 
        XLarge screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, "xl")) {
        .footer {
            &__shape {
                right: rem(-130);
                top: rem(-666);
            }
        }
    }
}
