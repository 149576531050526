@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";

.img-content-card {

    @include component-spacing;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;

    &__inner {
        padding-left: rem($padding);
        padding-right: rem($padding);
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-content: stretch;
        align-items: stretch;
        justify-content: center;
        @include shadow;
        overflow: hidden;
        border-radius: rem(2);
        background-color: $white;
        width: 100%;
        overflow: visible;
    }

    &__img {
        background-color: $very-light-pink;
        padding: 56.25% 0 0 0;
        width: 100%;
        max-height: rem(186);
        position: relative;
    }
    
    &__content {

        padding: rem(32) rem(24);
        
    }

    &__cta {
        margin: rem(24) 0 0 0;

        .btn {
            margin: 0 0 0 rem(10) 0;
        }
    }

    @include centre-align-container;

    &--no-shadow {
        .img-content-card__wrapper {
            box-shadow: none;
        }

        .img-content-card__content {
            padding: rem(32) 0;
        }
    }

}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .img-content-card {

        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        align-content: stretch;
        justify-content: center;

        &__img,
        &__content {
            width: 50%;
        }

        &__content {

            padding: rem(32) rem(42);
            display: flex;
            align-content: center;
            align-items: center;
            flex-flow: row wrap;
            justify-content: flex-start;

        }

        &--img-right {

            .img-content-card__img {
                order: 2;
            }

        }

        &--no-shadow {

            &.img-content-card--img-right {

                .img-content-card__content {
                    padding: 0 rem(42) 0 0;
                }
            }

            .img-content-card__content {
                padding: 0 rem(42);
            }
        }

    }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .animated {

        .img-content-card {
            transition: transform 1.1s ease, opacity 1s ease;
            opacity: 0;
            transform: translateY(20%);
        }

    }

    .is-visible {
        .img-content-card {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .img-content-card {

        &__img {
            min-height: rem(405);
            max-height: 100%;
        }

        &__content {
            padding: rem(60) rem(48);
        }

        &--no-shadow {

            &.img-content-card--img-right {

                .img-content-card__content {
                    padding: 0 rem(50) 0 0;
                }
            }

            .img-content-card__content {
                padding: 0 rem(50);
            }
        }

    }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .img-content-card {

        &__img {
            padding: 0 0 37.25% 0;
        }

        &__content {
            padding: rem(80) rem(85);
        }

        &__cta {
            margin: rem(40) 0 0 0;
        }

        &--no-shadow {

            &.img-content-card--img-right {

                .img-content-card__content {
                    padding: 0 rem(115) 0 0;
                }
            }

            .img-content-card__content {
                padding: 0 rem(60);
            }
        }

    }
}