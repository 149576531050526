@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.img-hero {
    width: 100%;
    height: 80vh;
    
    // img {
    //     display: block;
    //     width: 100%;
    //     height: auto;
    // }
}

#grad {
    background: linear-gradient(180deg,rgba(0,0,0,0.55) 3%,rgba(0,0,0,0) 11%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.34) 65%,rgba(0,0,0,0.61) 100%);
    width: 100%;
}
