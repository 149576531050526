@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .share {
            button {
                border-color: $itemColour;

                &:hover,
                &:focus {
                    background-color: $itemColour;

                    svg {
                        color: $white;
                    }
                }
            }

            button svg,
            &__tooltip ul li a svg {
                color: $itemColour;
            }

            &__tooltip ul li a:focus {
                border-color: $itemColour;
            }
        }
    }
}
