@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        $themeColour: $item;

        .youtube-video {
            &__bg {
                button {
                    svg {
                        color: $themeColour;
                    }
                }
            }
        }

        /*
        Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .youtube-video {
                &__bg button {
                    &:focus,
                    &:hover {
                        background-color: $themeColour;

                        svg {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

