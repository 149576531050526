@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./rtl";
@import "./_lang";

.definition-item {
    
    width: 100%;
    margin: rem(20) 0;
    transition: margin 0.3s ease;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    &__inner {
        width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    button {
        @include clear-default-appearance;
        padding: 0;
        cursor: pointer;
        color: $charcoal;
        text-align: left;
        border: none;
        display: block;
        width: 100%;
        position: relative;
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;
        padding: 0 rem(60) 0 0;

        &:focus {
            outline: 0;
            text-decoration: none;
        }

        svg {
            position: absolute;
            right: 0;
            top: rem(9);
            width: rem(18);
            height: rem(11);
            z-index: 1;
            transition: transform 0.4s ease;
        }

        &[aria-expanded="true"] {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        margin: rem(20) 0 0 0;

        p {
            font-size: rem(14);
            line-height: rem(24);
            color: $charcoal;
        }
    }

    &__resources {

        width: 100%;
        margin: rem(32) 0 0 0;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;

    }

    @include centre-align-container;
    
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .definition-item {

        button {

            &:hover,
            &:focus {
                outline: 0;
                text-decoration: underline;
            }
        }

        &__content {

            margin: rem(40) 0 0 0;
            max-width: rem(745);

            p {
                font-size: rem(16);
                line-height: rem(26);
            }
        }

        &__resources {
            margin: rem(40) 0 0 0;
        }

    }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .definition-item {

        margin: rem(30) 0;

        button {

            svg {
                top: rem(13);
            }

        }
    
        &__content {
            margin: rem(40) 0 0 0;
        }

    }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .definition-item {

        button {

            svg {
                top: rem(18);
            }

        }
    }

}