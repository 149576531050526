body.#{(map-get($languages, 'arabic'))} {

    /*
        Large Screens
    */

    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

        .instagram {
        
            &__left {

                h3,
                h5 {
                    text-align: right;
                }

            }

            &__right {
                &-inner {
                    right: 0;
                    left: inherit;
                }
            }

        }
        
    }

}