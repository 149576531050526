@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";

.feature-cta-row {

    width: 100%;

    h3 {
        margin: 0;
        padding: 0;
        color: $charcoal;
    }

    &__btn {
        margin: rem(30) 0 0 0;
    }

    &__col {
        width: 100%;
        max-width: rem(280);
    }

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .feature-cta-row {
        &__col {
            max-width: 80%;
        }
    }

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {


    .feature-cta-row {
        &__col {
            max-width: 70%;
        }
    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    
    .feature-cta-row {

        &__col {
            max-width: 55%;
        }

        &__btn {
            margin: rem(70) 0 0 0;
        }

    }

}