@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        .media-download-block {
            &__tag {
                background-color: $white;
                & > span {
                    color: $item;
                }

                svg {
                    color: $item;
                }
            }
        }
    }
}
