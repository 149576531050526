@each $theme, $item in $template-themes {
    body.#{$theme} {
        $themeColour: $item;

        .media-download-block {
            &__tag {
                background-color: $themeColour;
            }
        }
    }
}
