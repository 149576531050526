@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";

.social {

    width: 100%;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        flex-flow: row wrap;

        li {
            list-style: none;
            padding: 0;
            margin: 0 rem(10) 0 0;
            width: auto;

            &:before {
                display: none;
            }

            &:last-child {
                margin: 0;
            }

            a {
                width: rem(35);
                height: rem(34);
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                flex-flow: row wrap;

                &[target="_blank"] {

                    &:after {
                        content: url("../../assets/img/icon-new-tab-white.svg");
                    }

                }

                &:focus {
                    outline: 0;
                }

                svg {
                    width: rem(22);
                    height: rem(22);
                }
            }

            svg {
                color: $charcoal;
            }

        }
    }

    &--centre {

        ul {
            justify-content: center;
        }

    }

    &--vertical {
        ul {

            justify-content: center;

            li {
                width: auto;
                margin: 0 0 rem(12) 0;
            }
        }
    }


}


@media screen and (min-width:  map-get($grid-breakpoints, 'md')) {

    .social {

        ul li a {

            transition: opacity 0.5s ease, background-color 0.4s ease;

            svg {
                transition: color 0.4s ease;
            }

            &:hover {
                opacity: 0.5;
            }

            &:focus {
                border: rem(2) solid $charcoal;
            }
            
        }


    }

}