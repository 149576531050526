@each $bg, $item in $bg-styles {
    body.#{$bg} {
        .header:not(.is-sticky) {
            .nav-item {
                a {
                    color: $white;
                    &:after {
                        background-color: $white;
                    }
                }

                & > ul {
                    .nav-item {
                        a {
                            &:before,
                            &:after {
                                background-color: $white;
                            }
                        }
                    }
                }

                button {
                    svg {
                        color: $white;
                    }
                }
            }
        }

        .nav-item {
            & > ul {
                .nav-item {
                    a {
                        &:after {
                            right: rem(15);
                        }
                    }
                }
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .header:not(.is-sticky) {
                .nav-item {
                    button {
                        &:focus {
                            border: rem(2) solid $white;
                        }
                    }
                }
            }
        }

        /*
            XLarge Screens
        */

        @media screen and (min-width: map-get($grid-breakpoints, "xl")) {
            .header:not(.is-sticky) {
                .nav-item {
                    a,
                    button svg {
                        color: $white;
                    }

                    a:after {
                        background-color: $white;
                    }

                    & > ul {
                        .nav-item a,
                        .nav-item a:hover,
                        .nav-item a:focus {
                            color: $white;
                        }
                    }
                }
            }

            .nav-item {
                & > ul {
                    box-shadow: 0 0 rem(13) 0 rgba($black, 0.37);
                }
            }
        }
    }
}
