@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./bg";
@import "./theme";
@import "./comp-bg";
@import "./_rtl";

.arrow-btn {

    @include clear-default-appearance;
    width: rem(38);
    height: rem(38);
    border-radius: rem(999);
    background-color: $charcoal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-flow: row wrap;
    flex-flow: row wrap;
    transition: opacity 0.4s ease;
    border: rem(3) solid $charcoal;
    transition: background-color 0.4s ease;

    svg {
        color: $white;
        width: rem(14);
        height: rem(14);
        transition: margin 0.3s ease;
    }

    &:focus {
        outline: 0;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;

        &:focus {
            svg {
                margin: 0;
            }
        }

        svg {
            margin: 0;
        }

    }

    &.prev {

        svg {
            transform: rotate(90deg);
        }
    }

    &.next {

        svg {
            transform: rotate(-90deg);
        }
    }

}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .arrow-btn {

        &:hover,
        &:focus {

            background-color: $white;
            border-color: $charcoal;
            svg {
                color: $charcoal;
            }

        }

    }

}