@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";
@import "./_rtl";

.instagram {

    position: relative;
    @include component-spacing;

    &__carousel {
        width: 100%;
    }

    &__inner {
        width: 100%;
    }

    &__left {

        margin: 0 0 rem(30) 0;

        h3 {
            margin: 0 0 rem(5) 0;
            padding: 0;
            text-align: center;
            color: $charcoal;
            width: 100%;
            display: block;
        }

        h5 {
            color: $charcoal;
            margin: 0;
            padding: 0;
            width: 100%;
            display: block;
            text-align: center;
        }

        .instagram__platforms {
            display: none;
        }
        
    }

    &__platforms {

        margin: rem(32) 0 0 0;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
        width: 100%;

        .social ul {
            justify-content: center;
        }

        p {
            @include font-smoothing;
            text-align: center;
            color: $charcoal;
            margin: 0 0 rem(12) 0;
            padding: 0;
            font-size: rem(12);
            line-height: rem(22);
        }

    }

    .carousel-wrapper__container {
        max-width: rem(350);
    }
    
}


/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .instagram {

        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        position: relative;

        &__left {
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            align-items: flex-start;
            align-content: flex-start;
        }

        &__right {
            padding: 0;
            width: 100%;
        }

        


    }

}

/*
    Large Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .instagram {
        
        overflow: hidden;

        &__inner {
            display: flex;
            justify-content: space-between;
            flex-flow: row nowrap;
            align-items: center;
            align-content: center;
            padding: 0 rem($padding);
            max-width: map-get($container-max-widths, 'lg');
        }

        &__left,
        &__right {
            width: 50%;
        }

        &__left {
            justify-content: flex-start;
            margin: 0;

            h3 {
                text-align: left;
                max-width: rem(275);
                margin: 0 0 rem(10) 0;
            }

            h5 {
                text-align: left;
                margin: 0;
                width: 100%;
            }

            .instagram__platforms {
                display: flex;
            }

        }
        
        &__right {
            padding: 0;
            position: relative;
            height: rem(445);

            &-inner {
                position: absolute;
                left: 0;
                overflow: hidden;
                width: rem(5000);
                height: auto;
                display: flex;
                justify-content: flex-start;
                flex-flow: row wrap;
                align-content: flex-start;
                align-items: flex-start;

                .instagram__platforms {
                    display: none;
                }
            }
        }

        &__platforms {
            position: relative;
            left: inherit;
            bottom: inherit;
            justify-content: flex-start;
            margin: rem(30) 0 0 0;

            p {
                text-align: left;
                font-size: rem(14);
                line-height: rem(24);
            }

            .social {
                ul {
                    justify-content: flex-start;

                    li a {
                        width: rem(34);
                        height: rem(34);

                        svg {
                            width: rem(22);
                            height: rem(19);
                        }
                    }

                }
            }
        }

        .carousel {
            bottom: 0;
            position: relative;
            left: rem(-10);
            width: 100%;

            &__controls {
                justify-content: flex-start;
                margin: rem(40) 0 0 rem(145);
            }
        }

        .carousel-wrapper {
            
            overflow: visible;
            justify-content: flex-start;
            
            &__container {
                max-width: rem(363);
            }

        }

    }

}


/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .instagram {

        &__inner {
            max-width: rem(1127);
        }

        &__left {
            width: 40%;
        }

        &__right {
            width: 60%;
        }
        

    }

}