@each $bg, $item in $bg-styles {
    body.#{$bg}{
        
        .feature-cta-row {
            h3 {
                @include font-smoothing;
                color: $white;
            }

        }

    }
}