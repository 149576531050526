@each $theme, $item in $template-themes {
    body.#{$theme} {
        $themeColour: $item;

        .region-modal {
            &__title {
                background-color: $themeColour;
            }

            &__dropdown.dropdown ul li button.active,
            &__dropdown.dropdown ul li button:hover,
            &__dropdown.dropdown ul li button:focus,
            &__sections li.active button,
            &__controls span {
                color: $themeColour;
            }
        }
    }
}
