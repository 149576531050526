@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.accordion-group {

    // @include component-spacing;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;

    &__inner {
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    @include centre-align-container;

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .accordion-group {
        &__inner {
            max-width: rem(465);
        }
    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .accordion-group {

        &__inner {
            max-width: rem(745);
        }
        
    }

}