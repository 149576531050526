body.#{(map-get($languages, 'arabic'))}:not(.ie-11) {

    .campaign-card {

        &__icon {

            svg {
                transform: rotate(90deg);
            }
        }


    }

    /*
        Large Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        .campaign-card {

            &:hover,
            &:focus {

                .campaign-card__icon svg {
                    transform: rotate(90deg);
                }

            }

            &__icon {

                svg {
                    transform: rotate(0);
                }
            }

        }
    }

}