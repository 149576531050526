@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

/*
    Mixins
*/
@mixin cut-text($line-clamp-number, $max-height) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line-clamp-number;
    white-space: pre-wrap;
    max-height: $max-height;
    word-break: break-word;
}

.card-group {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: stretch;

    &__inner {
        padding-left: rem($padding);
        padding-right: rem($padding);
        width: 100%;
    }

    &__item {
        width: 100%;
        margin: 0 0 rem(12) 0;

        h4 {
            @include cut-text(3, 90px)
        }
    }

    @include centre-align-container;
}

/*
    Large screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .card-group {
        &__inner {
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            align-content: stretch;
            align-items: stretch;
        }

        &__item {
            width: 31%;
            margin: 10px 1% 0 1%;

            p {
                @include cut-text(3, 72px)
            }

            & > div {
                height: 100%;
            }
        }
    }
}

/*
    Extra Large screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    .card-group {
        &__item {
            p {
                @include cut-text(4, 100px)
            }
        }
    }
}
