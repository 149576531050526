@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_bg";

.nav {

    width: 100%;
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

    }

}

/*
    XLarge Screens
*/

@media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {

    .nav {

        ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            flex-flow: row wrap;

        }

    }

}