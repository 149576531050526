body.#{(map-get($languages, 'arabic'))} {

    .img-content-card {

        &__content {
            h3,
            p {
                text-align: right;
            }
        }
        
        &__cta {
            text-align: right;
        }

    }

    /*
        Large Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        
        .img-content-card {
            &__img {
                order: 2;
            }

            &__content {
                justify-content: flex-end;
            }
            
            &__cta {
                text-align: right;
            }

            &--no-shadow {

                &.img-content-card--img-right {

                    .img-content-card__content {
                        padding: 0 0 0 rem(50);
                    }
                }

            }
        }

    }

    /*
        XLarge Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

        .img-content-card {

            &--no-shadow {

                &.img-content-card--img-right {

                    .img-content-card__content {
                        padding: 0 0 0 rem(115);
                    }
                }
            }
        }
    }

}