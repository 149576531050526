body.#{(map-get($languages, 'arabic'))} {

    .carousel {

        &__prev {
            margin: 0 0 0 rem(10);
        }

        &__next {

            margin: 0 rem(10) 0 0;
        }

    }

    /*
        XLarge Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

        .animated.carousel-animation {
            .carousel {
                transform: translateX(-100%);
            }
        }

        .carousel-animation.is-visible {
            .carousel {
                transform: translateY(0);
            }
        }

    }


}