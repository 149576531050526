@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_rtl";

.social-content-col {

    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    
    &__icons {

        order: 2;
        width: 100%;
        margin: rem(40) 0 0 0;

        .social {

            width: 100%;

            ul {
                display: flex;
                justify-content: center;
                flex-flow: row wrap;
                align-content: center;
                align-items: center;
                padding: 0;

                li {

                    margin: 0 rem(16) 0 0;
                    padding: 0;

                    &:before {
                        display: none;
                    }

                    a {
                        width: rem(58);
                        height: rem(58);
                        display: flex;
                        justify-content: center;
                        flex-flow: row wrap;
                        align-content: center;
                        align-items: center;
                        border-radius: rem(999);
                        background-color: $charcoal;
                        border: rem(3) solid $charcoal;
                    }
                    
                    svg {
                        color: $white;
                        width: rem(25);
                        height: rem(25);
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }

        }
        
    }

}

/*
    Medium Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .social-content-col {

        &__icons {

            .social {
    
                ul {
    
                    li {
                        margin: 0 rem(35) 0 0;

                        a {
                            
                            &:hover,
                            &:focus {
                                opacity: 1;    
                                background-color: $white;
                                border-color: $charcoal;

                                svg {
                                    color: $charcoal;
                                }
                            }
                        }
                    }
                }
            }

        }

    }

}

/*
    XLarge Screens
*/

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .social-content-col {

        &__content {
            width: 40%;
        }

        &__icons {
            width: 50%;
            order: 0;
            display: flex;
            align-content: center;
            align-items: center;
            flex-flow: row wrap;
            justify-content: center;
            position: relative;


            .social {
                
                max-width: rem(310);
    
                ul {

                    width: 100%;
    
                    li {
                        margin: 0 rem(83) rem(43) 0;

                        &:nth-child(2){
                            margin-right: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
    
                        a {
                            width: rem(113);
                            height: rem(113);
                        }
                        
                        svg {
                            color: $white;
                            width: rem(48);
                            height: rem(48);
                        }
                    }
                }
            }

        }

    }

}