@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.figure-block-carousel {

    width: 100%;

    // &--grey {
    //     background-color: $white-two;
    //     padding-top: rem($component-spacing-xs);
	//     padding-bottom: rem($component-spacing-xs);
    // }

}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .figure-block-carousel {

        .carousel-wrapper__container {
            max-width: rem(250);
        }

        .carousel-wrapper__page {

            &.active:hover {
                opacity: 1;
            }

            &.prev,
            &.after {
                opacity: 1;
            }
        }

        // &--grey {
        //     padding-top: rem($component-spacing-md);
	    //     padding-bottom: rem($component-spacing-md);
        // }

    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .figure-block-carousel {

        // &--grey {
        //     padding-top: rem($component-spacing-lg);
	    //     padding-bottom: rem($component-spacing-lg);
        // }

    }

}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .figure-block-carousel {

        .carousel-wrapper__container {
            max-width: rem(363);
        }

        .carousel__controls {
            margin: rem(80) 0 0 0;
        }

        // &--grey {
        //     padding-top: rem($component-spacing-xl);
	    //     padding-bottom: rem($component-spacing-xl);
        // }

    }

}