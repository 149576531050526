// create bg styles
@each $bg, $item in $bg-styles {

    body.#{$bg}{
        .colour-card {

            a:hover,
            a:focus {
                
                .colour-card__icon {

                    background-color: $white;

                    svg {
                        color: $item;
                    }
                    
                }
            }
    
        }
    }

}