@each $bg, $item in $bg-styles {
    body.#{$bg}{
        
        .instagram-item {

            &__inner {

                &:focus {
                    box-shadow: inset 0 0 0 rem(4) $item;
                }

            }

        }

    }
}