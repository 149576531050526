body.#{(map-get($languages, 'arabic'))} {

    .side-bar {

        direction: ltr;
        left: inherit;
        right: 0;

        .social ul {
            justify-content: center;
        }
    }

    /*
        Large Screens
    */

    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

        .side-bar {
            transition: right 1s ease;
            right: 0;

            &.hide {
                right: -100%;
                left: inherit;
            }

            &.focus {
                left: inherit !important;
                right: 0 !important;
            }
        }
    }

}