@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.link-block-row {

    width: 100%;
    @include component-spacing;

    &__item {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0 0 rem(24) 0;
    }

    &__inner {
        width: 100%;
        max-width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    &__links {
        width: 100%;
        margin: rem(26) 0 rem(40) 0;
    }

    &__intro {

        h3 {
            padding-top: 0;
        }

    }

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .link-block-row {
        
        &__inner {
            max-width: rem(465);
        }

    }

}


/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .link-block-row {

        &__inner {
            max-width: rem(745);
        }

        &__links {
            margin: rem(40) 0 rem(80) 0;
        }


    }

}