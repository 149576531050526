@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_rtl";

.toolbar {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    width: 100%;
    padding-left: rem($padding);
    padding-right: rem($padding);

    .download-link {
        margin: 0 rem(20) 0 0;
    }
}

@media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    .toolbar {
        padding: 0;
        justify-content: center;
    }
}
