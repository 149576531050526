@mixin campaign-theme($color) {

    h4 {
        color: $color;
    }
    
    .campaign-card {
        
        &__lower {
            border-top: rem(4) solid $color;
        }

        &__icon {

            span {
                background-color: $color;
            }

        }
    }

}