@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.card-grid {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    @include component-spacing;

    &__inner {
        padding-left: rem($padding);
        padding-right: rem($padding);
        width: 100%;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
    }

    &__item {
        width: 100%;
        margin: 0 0 rem(12) 0;
    }

    &--full-width {
        .card-grid__inner {
            padding-left: 0;
            padding-right: 0;
        }
    }

}

/*
    Small Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {

    .card-grid {
        &__inner {
            max-width: map-get($container-max-widths, 'sm');
        }

        &--full-width {
            .card-grid__inner {
                max-width: 100%;
            }
        }

    }

}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .card-grid {

        &__inner {
            max-width: map-get($container-max-widths, 'md');
        }

        &__wrapper {
            justify-content: center;
            align-items: stretch;
            align-content: stretch;
        }    

        &__item {
            display: flex;
            align-content: stretch;
            align-items: stretch;
            flex-flow: row wrap;
            justify-content: center;
        }

        &--full-width {
            .card-grid__inner {
                max-width: 100%;
            }
        }

        &--two-col,
        &--three-col {

            .card-grid__wrapper {
                justify-content: flex-start;
            }

            .card-grid__item {
                width: 48%;
                margin: 0 2% rem(12) 0;

                &:nth-child(2n + 2) {
                    margin: 0 0 rem(12) 0;
                }
            }

        }
        
        
    }


}




/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .card-grid {

        &__inner {
            max-width: map-get($container-max-widths, 'lg');
        }

        &__item {
            width: 100%;
            margin: 0 0 rem(20) 0;
        }

        &--two-col {

            .card-grid__item {
                width: 48.75%;
                margin: 0 2.25% rem(20) 0;

                &:nth-child(2n + 2) {
                    margin: 0 0 rem(20) 0;
                }
            }

        }

        &--three-col {

            .card-grid__item {
                width: 30%;
                margin: 0 2.666666666% rem(20) 0;
    
                &:nth-child(2n + 2) {
                    margin: 0 2.666666666% rem(20) 0;
                }
    
                &:nth-child(3n + 3) {
                    margin: 0 0 rem(20) 0;
                }
            }

        }

        &--full-width {
            .card-grid__inner {
                max-width: 100%;
            }
        }

    }


}


/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .card-grid {

        &__inner {
            max-width: map-get($container-max-widths, 'xl');
        }

        &--full-width {
            .card-grid__inner {
                max-width: 100%;
            }
        }

        &--three-col {
            .card-grid__item {
                width: 32%;
    
                margin: 0 2% rem(20) 0;
    
                &:nth-child(2n + 2) {
                    margin: 0 2% rem(20) 0;
                }
    
                &:nth-child(3n + 3) {
                    margin: 0 0 rem(20) 0;
                }
            }
        }

    }


}