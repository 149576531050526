@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .social-content-col {
            &__icons {
                .social {
                    ul li {
                        a {
                            background-color: $itemColour;
                            border-color: $itemColour;
                        }

                        svg {
                            color: $white;
                        }
                    }
                }
            }
        }

        /*
        Medium Screens
        */

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .social-content-col {
                &__icons {
                    .social {
                        ul {
                            li {
                                a {
                                    &:hover,
                                    &:focus {
                                        background-color: $white;
                                        border-color: $itemColour;

                                        svg {
                                            color: $itemColour;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
