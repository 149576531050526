@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.content-card-carousel {

    width: 100%;

}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .content-card-carousel {

        .carousel-wrapper__page {
            &.active:hover {
                opacity: 1;
            }
        }

    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .content-card-carousel {

        .carousel-wrapper__page {
            &.prev,
            &.after {
                pointer-events: all;
                opacity: 1;
            }
        }

    }


}