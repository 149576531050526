body.#{(map-get($languages, 'arabic'))} {

    .content-banner {

        h1 {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            align-items: flex-start;
            text-align: right;
        }

        h2 {
            text-align: right;
        }

        h3 {
            right: rem($padding);
            left: inherit;
        }

        // Large screens
        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

            h2 {
                padding: 0 rem(150) 0 rem(50);
            }

            h3 {
                right: rem(20);
            }

        }

    }


}