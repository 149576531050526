body.#{(map-get($languages, 'arabic'))} {

    .definition-item {

        button {
            
            padding: 0 0 0 rem(60);
            direction: rtl;
            text-align: right;

            svg {
                left: 0;
                right: inherit;
            }

        }

    }

}