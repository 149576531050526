@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.background {

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;

    &__item {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: opacity 5s ease, visibility 5.1s;
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &.bg-blue {
            background-color: $bluish;
        }

        &.bg-orange {
            background-color: $sienna;
        }

        &.bg-red {
            background-color: $red-wine;
        }

        &.bg-purple {
            background-color: $darkish-purple;
        }

        &.bg-green {
            background-color: $spruce;
        }
        
    }

}