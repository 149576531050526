@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .definition-item {
            button {
                color: $itemColour;
            }
        }
    }
}
