@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        .figure-block {
            &__value,
            &__content {
                color: $white;
            }
        }
    }
}
