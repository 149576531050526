@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .download-link {
            border-color: $itemColour;

            svg {
                color: $itemColour;
            }

            &:hover,
            &:focus {
                background-color: $itemColour;

                svg {
                    color: $white;
                }
            }
        }
    }
}
