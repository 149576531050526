body.#{(map-get($languages, 'arabic'))} {

    /*
        XLarge Screens
    */
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

        .grey-content-row {
            
            &--columns {

                .grey-content-row__left {
                    text-align: right;
                }
            }
        }
    }

}