@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.post-card-grid {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    background-color: $white-two;
    padding: rem(60) 0;

    &.loading {
        .post-card-row {
            opacity: 0;
        }

        .post-card-grid__controls {
            .btn {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
    
    &__inner {
        width: 100%;
        padding-left: rem($padding);
        padding-right: rem($padding);
        max-width: rem(510);
    }

    &__controls {
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 1;
        margin: rem(40) 0 0 0;
    }

    .post-card-row {
        transition: opacity 0.4s ease;
        opacity: 1;
    }
    
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    body {
        opacity: 1;
        transition: opacity 0.4s ease;

        &.loading {
            opacity: 0;
        }
    }


    .post-card-grid {

        padding: rem(80) 0;

        &__inner {
            max-width: 100%;
        }
        
        &__controls {

            margin: rem(60) 0 0 0;

            button.btn {
                width: auto;
                min-width: rem(195);
            }
        }


    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .post-card-grid {

        &__inner {
            max-width: map-get($container-max-widths, 'lg');
        }

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .post-card-grid {

        padding: rem(160) 0;

        &__inner {
            max-width: map-get($container-max-widths, 'xl');
        }

        &__controls {
            margin: rem(80) 0 0 0;
        }


    }

}