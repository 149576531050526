@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_comp-bg";
@import "./_rtl";

.share {
    width: auto;
    display: inline-block;
    position: relative;

    button {
        @include clear-default-appearance;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        align-content: center;
        justify-content: flex-start;
        text-decoration: none;
        font-size: rem(12);
        line-height: rem(20);
        border: rem(2) solid $charcoal;
        border-radius: rem(2);
        padding: rem(9) rem(18) rem(9) rem(12);
        color: $charcoal;
        font-weight: 400;
        transition: background-color 0.4s ease;
        cursor: pointer;

        svg {
            width: rem(18);
            height: rem(18);
            min-width: rem(18);
            min-height: rem(18);
            color: $charcoal;
            margin: 0 rem(10) 0 0;
        }

        &:hover,
        &:focus {
            outline: 0;
            text-decoration: none;
            background-color: $charcoal;
            color: $white;

            svg {
                color: $white;
                outline: 0;
            }
        }
    }

    &__tooltip {
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        border-radius: rem(4);
        width: rem(100);
        z-index: 1;
        border: rem(1) solid rgba($charcoal, 0.2);

        ul {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            padding: 0;
            margin: 0;

            li {
                padding: 0;
                margin: 0;
                width: 100%;

                &:before {
                    display: none;
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    flex-flow: row wrap;
                    width: 100%;
                    padding: rem(8) 0;

                    &:hover,
                    &:focus {
                        outline: 0;
                        border: none;
                    }

                    &:after {
                        display: none;
                    }

                    svg {
                        width: rem(18);
                        height: rem(18);
                        transition: color 0.6s ease, opacity 0.4s ease;
                        color: $charcoal;
                    }
                }
            }
        }
    }

    &--no-toggle {
        .share__tooltip {
            position: static;
            transform: none;
            background-color: transparent;
            z-index: unset;
            border: none;
            width: unset;
            ul {
                li {
                    margin: 0 rem(10) 0 0;

                    &:last-child {
                        margin: 0;
                    }

                    a {
                        width: rem(35);
                        height: rem(34);
                        padding: 0;

                        svg {
                            width: rem(22);
                            height: rem(22);
                        }
                    }
                }
            }
        }
    }
}

/*
    Medium screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    .share {
        &__tooltip {
            ul {
                li {
                    a {
                        &:hover,
                        &:focus {
                            outline: 0;

                            svg {
                                color: $charcoal;
                            }
                        }

                        &:focus {
                            border: rem(2) solid $charcoal;
                        }

                        &:hover svg {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}

/*
    XLarge screens
*/
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .share {
        &__tooltip {
            left: 50%;
            top: calc(100% + 15px);
            transform: translateX(-50%);
            box-shadow: 0 rem(2) rem(8) 0 rgba($black, 0.15);
            border: none;

            &:before {
                content: "";
                display: block;
                left: 50%;
                top: rem(-10);
                transform: translateX(-50%);
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 rem(7.5) rem(10) rem(7.5);
                border-color: transparent transparent $white transparent;
            }
        }

        &--no-toggle {
            .share__tooltip {
                transform: none;
                box-shadow: none;
                
                &:before {
                    display: none;
                }
            }
        }
    }
}
