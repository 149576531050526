body.#{(map-get($languages, 'arabic'))} {

    .social {

        ul {

            li {
                margin: 0 0 0 rem(10);
            }

        }

        &--vertical {
            ul {

                li {
                    margin: 0 0 rem(12) 0;
                }
            }
        }

    }

}