@import "../../styles/imports/variables";
@import "../../styles/imports/utils";

.post-card-row {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: stretch;
    align-items: stretch;

    &__column,
    &__feature-col {
        width: 100%;
    }

    &__column {
        
        order: 0;

        .update-card,
        .campaign-card {
            margin: 0 0 rem(20) 0;
        }
    }

    &__feature-col {
        order: 2;
        margin: 0 0 rem(20) 0;
    }


    &--right {

        .post-card-row__column {
            order: 0;
        }

        .post-card-row__feature-col {
            order: 2;
        }

    }


}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

    .post-card-row {

        &__column,
        &__feature-col {
            display: flex;
            flex-flow: row wrap;
            align-items: stretch;
            align-content: stretch;
        }

        &__column {

            justify-content: space-between;

            .update-card,
            .campaign-card {
                margin: 0 0 rem(20) 0;
                width: 48.75%;
                margin: 0 0 rem(20) 0;
            }
        }

        &__feature-col {
            margin: 0 0 rem(20) 0;
        }

    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .post-card-row {

        margin: 0 0 rem(20) 0;

        &:last-child {
            margin: 0;
        }

        &__column,
        // &__feature-col {
        //     width: 49.4%;
        // }


        &__feature-col {
            margin: 0;
            order: 0;
        }

        &__column {
            order: 2;
            justify-content: space-between;

            .update-card,
            .campaign-card {
            
                width: 48.75%;
                margin: 0 0 2.75% 0;

                // &:nth-child(3),
                // &:nth-child(4){
                //     margin: 0;
                // }
            }
        }

        &__feature-col {
            justify-content: center;
        }

    }

}