@import "../../styles/imports/variables";
@import "../../styles/imports/utils";
@import "./_theme";
@import "./_rtl";

.carousel {

    overflow: hidden;
    @include component-spacing;
    padding: 0px !important;
    
    &__controls {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;
        width: 100%;
        margin: rem(40) 0 0 0;
    }

    &__lower {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
    }

    &__prev {
        margin: 0 rem(10) 0 0;
    }

    &__next {

        margin: 0 0 0 rem(10);
    }

    .share {
        padding: 0 rem($padding);
        margin: rem(40) 0 0 0;
    }

    .social {
        width: 100%;
        padding: 0 rem($padding);
        margin: rem(40) 0 0 0;

        ul {
            justify-content: center;
        }
    }

    &--block {
        .carousel-wrapper__container {
            max-width: rem(320);
        }
    }
    
}

/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    
    .carousel {

        &--small {

            .carousel-wrapper__container {
                max-width: rem(380);
            }

        }

        &--block {
            .carousel-wrapper__container {
                max-width: rem(280);
            }
        }

        &--figure {

            .carousel-wrapper__page.prev,
            .carousel-wrapper__page.after {
                opacity: 1;
            }

            .carousel-wrapper__container {
                max-width: rem(200);
            }
        }
    }

}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

    .carousel {

        &--figure {

            .carousel-wrapper__container {
                max-width: rem(300);
            }
        }
    }

}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {

    .animated.carousel-animation {

        overflow: hidden;

        .carousel {
            transition: transform 0.9s ease, opacity 1.4s ease;
            transform: translateX(100%);
            opacity: 0;
            transition-delay: 0.4s;

            &__controls {
                opacity: 0;
                transition: opacity 0.6s ease;
                transition-delay: 1.3s;
            }
        }
    }

    .carousel-animation.is-visible {
        .carousel {
            opacity: 1;
            transform: translateY(0);
            
            &__controls {
                opacity: 1;
            }
        }
    }
    
    .carousel {

        &__controls {
            margin: rem(40) 0 0 0;
        }

        .share {
            margin: rem(60) 0 0 0;

        }

        .social {
            margin: rem(60) 0 0 0;

            ul {

                li {

                    a {
                        width: rem(34);
                        height: rem(34);
                    }

                    svg {
                        width: rem(24);
                        height: rem(22);
                    }

                }

            }
        }

        &--block {
            .carousel-wrapper__container {
                max-width: rem(365);
            }
        }

        &--figure {
            .carousel-wrapper__container {
                max-width: rem(365);
            }
        }

    }

}